import { useNavigate, useLocation } from 'react-router-dom';

import { useState, useEffect } from 'react';
import powerd from '../../assets/logos/powerd.svg';
import logo_light from '../../assets/logos/logo-chat-light.svg';
import logo_dark from '../../assets/logos/logo-chat-dark.svg';
const Footer = () => {
  const navigate = useNavigate();

  const [theme, setTheme] = useState('light');

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };
  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);
  const goChat = () => {
    navigate('/post-chat?UserId=39');
  };
  return (
    <footer
      className='bg-[#f8f6f3] dark:bg-[#110130] font-arabic py-6 relative z-40'
      dir='rtl'>
      <div className='max-w-screen-xl mx-auto px-4 flex justify-between md:flex-row-reverse flex-col-reverse md:gap-0 gap-3 items-center'>
        {/* Left Section */}
        <div className='relative'>
          {/* <span className='text-sm text-gray-600 mr-2'>Powered by</span> */}

          <img
            src={theme === 'light' ? logo_light : logo_dark}
            onClick={toggleTheme}
            alt='Mena Logo'
            className='inline-block h-[52px] w-[110px] cursor-pointer  ml-2'
          />
          {/* <p className=' text-[#4c00ff]  dark:text-white absolute -left-12 top-4 md:-left-12 md:top-4'>
            powered by{' '}
          </p> */}
        </div>

        {/* Center Section */}
        <div className='text-sm md:text-lg text-[#110130] dark:text-white text-center'>
          <a href='https://example.com'>اذا كنت طبيب او من السلك الطبي </a>
          <hr className='md:hidden block' />
          <button className='text-[#5d1dd0]' onClick={goChat}>
            راسلنا الآن{' '}
          </button>
        </div>

        {/* Right Section */}
        {/* <div className='flex space-x-4 text-xl text-gray-600 dark:text-white'>
          <a href='/terms' className='hover:text-[#5d1dd0]'>
            الشروط والأحكام
          </a>
          <a href='/privacy' className='hover:text-[#5d1dd0]'>
            سياسة الخصوصية
          </a>
        </div> */}
      </div>
      <div className='absolute bottom-0 left-0 right-0 h-1 bg-[#5d1dd0]'></div>
    </footer>
  );
};

export default Footer;
