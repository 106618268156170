import React, { useRef, useState, useEffect } from 'react';
import MainHeader from '../../components/Header/MainHeader';
import axios from '../../utilities/axios';
import sendIcon from '../../assets/icons/send.svg';
import cameraIcon from '../../assets/icons/camera.png';
import { XCircleIcon } from '@heroicons/react/24/solid';
import autosize from 'autosize';
import profileIcon from '../../assets/icons/profile.png';
import mena from '../../assets/icons/mena.png';

const Chat = () => {
  const textareaRef = useRef(null);
  const bottomRef = useRef(null);
  const cameraInputRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isSendDisabled, setIsSendDisabled] = useState(false);

  useEffect(() => {
    autosize(textareaRef.current);
  }, []);
  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, selectedImage]);
  const handleCameraClick = () => {
    if (cameraInputRef.current) {
      cameraInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setIsSendDisabled(false);
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    cameraInputRef.current.value = '';
  };

  const handleSendMessage = async () => {
    const text = textareaRef.current.value;

    if (!text.trim() && !selectedImage) return;

    setIsSendDisabled(true);
    setSelectedImage(null);
    textareaRef.current.value = '';

    const formData = new FormData();
    formData.append('query', text);
    if (cameraInputRef.current.files[0]) {
      formData.append('image', cameraInputRef.current.files[0]);
    }

    const newMessage = {
      sender: 'user',
      text,
      image: selectedImage,
      timestamp: new Date().toISOString(),
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);

    try {
      const response = await axios.post('/users/chatAI', formData);

      if (response.data) {
        const aiMessage = {
          sender: 'mena',
          text: response.data.answer.text,
          timestamp: response.data.answer.timestamp,
        };
        setMessages((prevMessages) => [...prevMessages, aiMessage]);

        console.log({ messages });
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }

    textareaRef.current.value = '';
    setSelectedImage(null);
    console.log('Selected image:', selectedImage);
    cameraInputRef.current.value = '';
    setIsSendDisabled(false);
  };

  return (
    <div className='min-h-screen bg-[#F6F2EF] dark:bg-[#110130] font-arabic flex flex-col'>
      <div className='sticky mb-5 top-0 bg-[#F6F2EF] dark:bg-[#110130] z-10'>
        <MainHeader showRefresh={true} />
      </div>

      <div className='flex-1 overflow-y-auto px-4 space-y-4'>
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex ${
              message.sender === 'user' ? 'justify-end' : 'justify-start'
            }`}>
            <div
              className={`${
                message.sender === 'user'
                  ? 'bg-white text-black'
                  : 'bg-chat-light-bg text-white dark:bg-chat-dark-bg'
              } rounded-3xl p-4 shadow-md w-[90%] rtl`}>
              <div className='flex items-center gap-2 justify-start mb-2'>
                <img
                  src={message.sender === 'user' ? profileIcon : mena}
                  alt='Profile'
                  className={`h-${
                    message.sender === 'user' ? '12' : '[37px]'
                  } w-${
                    message.sender === 'user' ? '12' : '[37px]'
                  } rounded-full`}
                />
                <div className='flex flex-col text-right'>
                  <div
                    className={
                      message.sender === 'user'
                        ? 'text-[20px] text-black'
                        : 'text-[20px] text-white'
                    }>
                    {message.sender === 'user' ? 'أنت' : 'مينا'}
                  </div>
                </div>
              </div>
              <div
                style={{
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                }}
                className={
                  message.sender === 'user'
                    ? 'text-[20px] text-black text-right  whitespace-pre-wrap'
                    : 'text-[20px] text-white text-right  whitespace-pre-wrap'
                }>
                {message.text}
              </div>
              {message.image && (
                <img
                  src={message.image}
                  alt='Uploaded'
                  className='mt-4 w-20 h-20 rounded-lg'
                />
              )}
            </div>
          </div>
        ))}
        <div ref={bottomRef} />
      </div>

      {selectedImage && (
        <div className='relative flex items-center justify-center mb-4'>
          <div className='relative'>
            <img
              src={selectedImage}
              alt='Selected'
              className='w-20 h-20 rounded-lg'
            />
            <XCircleIcon
              onClick={handleRemoveImage}
              className='absolute right-[-10px] top-[-10px] cursor-pointer text-black bg-white rounded-full h-6 w-6'
            />
          </div>
        </div>
      )}

      <div className='p-4 bg-transparent sticky bottom-0 flex items-end'>
        <div className='flex items-center bg-white border-[1px] border-[#fff] dark:bg-[#110130] rounded-full p-2 shadow-md flex-1'>
          <img
            src={cameraIcon}
            alt='Camera'
            className='h-6 w-6 mx-2 cursor-pointer'
            onClick={handleCameraClick}
          />
          <textarea
            ref={textareaRef}
            rows='1'
            placeholder='اكتب استفسارك الطبي ...'
            className='flex-1 bg-transparent border-none !text-right focus:ring-0 focus:outline-none text-black dark:text-white px-4 resize-none text-lg max-h-[80px] overflow-y-auto'
          />
          <input
            type='file'
            ref={cameraInputRef}
            accept='image/*'
            capture='environment'
            className='hidden'
            onChange={handleFileChange}
          />
        </div>
        <div
          className={`cursor-pointer mr-2 bg-[#4C00FF] rounded-full p-1 ${
            isSendDisabled ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={!isSendDisabled ? handleSendMessage : null}>
          <img src={sendIcon} alt='Send' className='h-10 w-10 p-1' />
        </div>
      </div>

      {showCopiedMessage && (
        <div className='fixed bottom-28 left-1/2 transform -translate-x-1/2 bg-[#969696] text-white px-4 py-2 rounded-full'>
          تم النسخ إلى الحافظة
        </div>
      )}
    </div>
  );
};

export default Chat;
