import { useEffect } from 'react';
import splashImage from '../../assets/logos/splash-image.svg';

const SplashScreen = ({ onFinish }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onFinish();
    }, 0);

    return () => clearTimeout(timer);
  }, [onFinish]);

  return (
    <div className='flex items-center justify-center min-h-screen bg-black overflow-hidden'>
      <div className='w-full h-full flex items-center justify-center'>
        <img
          src={splashImage}
          alt='Splash Screen'
          className='w-full max-h-screen object-cover'
        />
      </div>
    </div>
  );
};

export default SplashScreen;
