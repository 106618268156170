import React, { useRef, useState, useEffect } from 'react';
import MainHeader from '../../components/Header/MainHeader';
import axios from '../../utilities/axios';
import sendIcon from '../../assets/icons/send.svg';
import cameraIcon from '../../assets/icons/camera.png';
import { XCircleIcon, ArrowLongUpIcon } from '@heroicons/react/24/solid';
import ScrollableFeed from 'react-scrollable-feed';
import autosize from 'autosize';
import { io } from 'socket.io-client';
import bg_light from '../../assets/logos/bg-chat-light.svg';
import mena from '../../assets/logos/mena.svg';
import notif from '../../assets/sounds/notif.mp3';

const PostChat = () => {
  const textareaRef = useRef(null);
  const cameraInputRef = useRef(null);
  const bottomRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isSendDisabled, setIsSendDisabled] = useState(false);
  const [chatPage, setChatPage] = useState(1);
  const [otherUserID, setOtherUserID] = useState(null);
  const [isTabActive, setIsTabActive] = useState(true);
  const socket = useRef(null);
  const [emptyMessageArray, setEmptyMessageArray] = useState(false);
  const senderID = localStorage.getItem('senderID');
  const accessToken = localStorage.getItem('accessToken');
  const fcmToken = localStorage.getItem('fcmToken');
  const searchParams = new URLSearchParams(location.search);
  const [ConversationId, setConversationId] = useState(null);
  const [page, setPage] = useState(1);
  const scrollableRef = useRef(null);
  const initialRender = useRef(true);
  const userId = searchParams.get('UserId');
  const conversation_id = searchParams.get('conversationId');

  useEffect(() => {
    autosize(textareaRef.current);

    socket.current = io(`https://www.mena4health.com`, {
      path: '/apis/socket.io',
      auth: { token: accessToken || null, FCMToken: fcmToken || null },
      // add fcm token and check if the token is exist or not and save it when connect event exist
    });
    socket.current.on('connect', () => {
      console.log('Connected to socket');

      if (!accessToken) {
        console.log('hereeeeeeeeeee');
        socket.current.on('connected', (data) => {
          console.log({ data });
          localStorage.setItem('accessToken', data.token);
          localStorage.setItem('senderID', data.userId);
        });
      }
    });
    if (userId) {
      console.log('i am user id');
      axios
        .get(`/users/conversations?page=1&size=1&UserId=${userId}`)
        .then((response) => {
          const conversation = response.data[0];
          if (conversation) {
            const convoId = conversation.id;
            setConversationId(convoId);
            setPage(1);
          }
        })
        .catch((error) => {
          console.error('Error fetching conversations:', error);
        });
    }
    if (conversation_id) {
      console.log('i am conversation_id');

      setConversationId(conversation_id);
    }

    socket.current.on('chat_received', (data) => {
      console.log('chat_received', data);
      const newMessage = {
        ...data.message,
        timestamp: new Date(data.message.createdAt),
      };

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages, newMessage];
        return updatedMessages.sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        );
      });
      setTimeout(() => {
        scrollToBottom();
      }, 200);

      socket.current.emit('acknowledge_message', {
        messageId: data.message.id,
      });

      if (!isTabActive) {
        sendNotification(data.message.text);
      }
    });

    socket.current.on('chat_sent', (data) => {
      console.log('chat_sent event received:', data);
      setConversationId(data.ConversationId);
    });

    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    requestNotificationPermission();

    return () => {
      socket.current.disconnect();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const fetchMessages = async (ConversationId) => {
    try {
      const response = await axios.post('/users/messages', {
        ConversationId,
        page,
        size: 10,
      });

      const newMessages = response.data;
      if (newMessages.length === 0 || newMessages.length < 10) {
        setEmptyMessageArray(true);
      }

      setMessages(newMessages.reverse());
      if (conversation_id) {
        const firstMessage = messages[0];
        console.log({ firstMessage });
        const otherUserID =
          firstMessage.senderId === +senderID
            ? firstMessage.receiverId
            : firstMessage.senderId;
        setOtherUserID(otherUserID);
        console.log({ otherUserID }, senderID);
      }

      setTimeout(() => {
        scrollToBottom();
      }, 200);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const fetchScrollMessages = async (ConversationId) => {
    try {
      const response = await axios.post('/users/messages', {
        ConversationId,
        page,
        size: 10,
      });

      let newMessages = response.data;
      if (newMessages.length < 10) {
        setEmptyMessageArray(true);
      }

      console.log({ newMessages });
      const reversedMessages = [...newMessages].reverse();
      console.log(reversedMessages);

      setMessages((prevMessages) => [...reversedMessages, ...prevMessages]);
      console.log({ messages });
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  useEffect(() => {
    if (ConversationId) {
      fetchMessages(ConversationId);
    }
  }, [ConversationId]);

  /*----------------------------------------------------------------
....................................
..........................
........*/

  const handleScroll = async () => {
    if (scrollableRef.current.scrollTop === 0) {
      console.log(
        'Scrolllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll'
      );
    }
  };
  useEffect(() => {
    const scrollableDiv = scrollableRef.current;
    console.log({ scrollableDiv });
    if (scrollableDiv) {
      scrollableDiv.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const scrollToTop = () => {
    setPage((prev) => prev + 1);
  };

  useEffect(() => {
    if (!initialRender.current && page > 1) {
      fetchScrollMessages(ConversationId);
    } else {
      initialRender.current = false;
    }
  }, [page]);

  const requestNotificationPermission = () => {
    // Check if notifications are supported by the browser
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notifications.');
      return;
    }

    // Check if running on iOS Safari (no notification support)
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isIOS && isSafari) {
      console.log('Notifications are not supported on iOS Safari.');
      return;
    }

    // Request notification permission if not already granted or denied
    if (
      Notification.permission !== 'granted' &&
      Notification.permission !== 'denied'
    ) {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === 'granted') {
            console.log('Notification permission granted');
          } else {
            console.log('Notification permission denied');
          }
        })
        .catch((error) => {
          console.error('Failed to request notification permission:', error);
        });
    }
  };

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [selectedImage]);

  const sendNotification = (message) => {
    console.log('here am');
    if (Notification.permission === 'granted') {
      const notificationOptions = {
        body: message,
        icon: mena,
        requireInteraction: false,
        silent: true,
      };

      const notification = new Notification('New Message', notificationOptions);

      const audio = new Audio(notif);
      audio.play();

      setTimeout(() => {
        notification.close();
      }, 3000);

      notification.onclick = () => {
        window.focus();
      };
    }
  };

  const handleCameraClick = () => {
    if (cameraInputRef.current) {
      cameraInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setIsUploading(true);
      setIsSendDisabled(true);

      try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post('/users/uploadImage', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data && response.data[0] && response.data[0].path) {
          setImagePath(response.data[0].path);
        }

        setIsUploading(false);
        setIsSendDisabled(false);
      } catch (error) {
        console.error('Error uploading image:', error);
        setIsUploading(false);
        setIsSendDisabled(false);
      }
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setImagePath(null);
    cameraInputRef.current.value = '';
  };

  const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setShowCopiedMessage(true);
        setTimeout(() => setShowCopiedMessage(false), 2000);
      },
      (err) => {
        console.error('Failed to copy text: ', err);
      }
    );
  };

  const handleSendMessage = (event) => {
    // event.preventDefault();
    const text = textareaRef.current.value;

    if (!text.trim() && !imagePath) return;

    const tempId = Date.now();
    const newMessage = {
      id: tempId,
      senderId: senderID,
      text,
      image: imagePath,
      createdAt: new Date().toISOString(),
      timestamp: new Date().toISOString(),
      isSentByUser: true,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setTimeout(() => {
      scrollToBottom();
    }, 200);
    socket.current.emit(
      'chat_message',
      {
        text,
        image: imagePath,
        ReceiverId: userId ? +userId : otherUserID,
        ConversationId: ConversationId,
      },
      (acknowledgment) => {
        if (acknowledgment.status === 'received') {
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === tempId
                ? { ...msg, id: acknowledgment.messageId, status: 'sent' }
                : msg
            )
          );
        }
      }
    );

    textareaRef.current.value = null;
    setSelectedImage(null);
    setImagePath(null);
    autosize.destroy(textareaRef.current);
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const sortedMessages = [...messages].sort(
    (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
  );

  const handleKeyDown = (event) => {
    console.log({ event });
    if (event.key === 'Enter' && !event.shiftKey) {
      console.log(event.key);
      handleSendMessage();
    }
  };

  return (
    <div
      className='min-h-screen bg-[#F6F2EF] dark:bg-[#110130] font-arabic flex flex-col'
      dir='rtl'>
      <div className='sticky mb-5 top-0 bg-[#F6F2EF] dark:bg-[#110130] z-10'>
        <MainHeader showRefresh={true} />
      </div>

      {messages.length === 0 && (
        <div className='flex-1 flex items-center justify-center'>
          <img
            src={bg_light}
            alt='No messages yet'
            className='max-h-[70vh] max-w-[90vw] h-auto w-auto'
          />
        </div>
      )}
      <div
        ref={scrollableRef}
        className='flex-1 overflow-y-auto px-4 space-y-4'>
        {!emptyMessageArray && messages.length > 0 && (
          <h2
            onClick={scrollToTop}
            className='w-full cursor-pointer hover:text-[#3c69a3] flex justify-center items-center'>
            تحميل المزيد
            <ArrowLongUpIcon className='w-4 hover:text-[#3c69a3] h-4 mr-4' />
          </h2>
        )}
        {sortedMessages.map((msg, index) => (
          <div
            key={index}
            className={`flex ${
              msg.senderId == senderID ? 'justify-start' : 'justify-end'
            }`}>
            <div
              className={`flex items-center relative gap-8 p-4 max-w-[80%] rounded-3xl shadow-md ${
                msg.senderId == senderID
                  ? 'bg-[#4C00FF] text-white'
                  : 'bg-white text-black'
              }`}>
              {msg.image && (
                <div className='relative w-full h-40'>
                  <img
                    src={msg.image}
                    alt='Uploaded'
                    className='object-cover w-full h-full rounded-lg'
                  />
                </div>
              )}
              <span className='text-xs  w-[64px]'>
                {new Date(msg.createdAt).toLocaleTimeString()}
              </span>
              <p className='text-sm mb-1'>{msg.text}</p>
              <div
                className={`absolute bottom-[-6px] w-0 h-0 border-[12px] ${
                  msg.senderId == senderID
                    ? 'right-0 border-transparent border-t-[#4C00FF] border-r-[#4C00FF] rotate-[-36deg]'
                    : 'left-0 border-transparent border-t-white border-l-white rotate-[36deg]'
                }`}
              />
            </div>
          </div>
        ))}

        <div ref={bottomRef} />
      </div>

      {selectedImage && (
        <div className='relative flex items-center justify-center mb-4'>
          <div className='relative'>
            <img
              src={selectedImage}
              alt='Selected'
              className='w-20 h-20 rounded-lg'
            />
            {isUploading && (
              <div className='absolute inset-0 flex items-center justify-center bg-white bg-opacity-50'>
                <div className='loader'></div>
              </div>
            )}
            <XCircleIcon
              onClick={handleRemoveImage}
              className='absolute right-[-10px] top-[-10px] cursor-pointer text-black bg-white rounded-full h-6 w-6'
            />
          </div>
        </div>
      )}

      <div className='p-4 bg-transparent sticky bottom-0 flex items-center'>
        <div
          className={`cursor-pointer ml-2 bg-[#4C00FF] rounded-full p-2 ${
            isSendDisabled ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={!isSendDisabled ? handleSendMessage : null}>
          <img src={sendIcon} alt='Send' className='h-10 w-10 p-1' />
        </div>
        <div className='flex items-center bg-white border-[1px] border-[#fff] dark:bg-[#110130] rounded-full p-2 shadow-md flex-1'>
          <textarea
            ref={textareaRef}
            rows='1'
            onKeyDown={handleKeyDown}
            placeholder='اكتب استفسارك'
            className='flex-1 bg-transparent border-none !text-right focus:ring-0 focus:outline-none text-black dark:text-white px-4 resize-none text-lg max-h-[80px] overflow-y-auto'
          />
          <input
            type='file'
            ref={cameraInputRef}
            accept='image/*'
            className='hidden'
            onChange={handleFileChange}
          />
          <div
            className={`cursor-pointer mr-2 rounded-full p-1 ${
              isSendDisabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={() => cameraInputRef.current.click()}>
            <img src={cameraIcon} alt='Send' className='h-8 w-8 p-1' />
          </div>
        </div>
      </div>

      {showCopiedMessage && (
        <div className='fixed bottom-28 left-1/2 transform -translate-x-1/2 bg-[#969696] text-white px-4 py-2 rounded-full'>
          تم النسخ إلى الحافظة
        </div>
      )}
    </div>
  );
};

export default PostChat;
