import React, { useRef, useState, useEffect } from 'react';
import MainHeader from '../../components/Header/MainHeader';
import axios from '../../utilities/axios';
import sendIcon from '../../assets/icons/send.svg';
import cameraIcon from '../../assets/icons/camera.png';
import { XCircleIcon, ArrowLongUpIcon } from '@heroicons/react/24/solid';
import autosize from 'autosize';
import { io } from 'socket.io-client';
import { useParams } from 'react-router-dom'; // To get conversationId from the URL
import mena from '../../assets/logos/mena.svg';
import notif from '../../assets/sounds/notif.mp3';

const ChatFromNotification = () => {
  const { ConversationId } = useParams(); // Get the conversationId from the URL params
  const textareaRef = useRef(null);
  const cameraInputRef = useRef(null);
  const bottomRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isSendDisabled, setIsSendDisabled] = useState(false);
  const [chatPage, setChatPage] = useState(1);
  const [isTabActive, setIsTabActive] = useState(true);
  const socket = useRef(null);
  const senderID = localStorage.getItem('senderID');
  const accessToken = localStorage.getItem('accessToken');
  const scrollableRef = useRef(null);

  useEffect(() => {
    autosize(textareaRef.current);

    socket.current = io(`https://www.mena4health.com`, {
      path: '/apis/socket.io',
      auth: { token: accessToken },
    });

    socket.current.on('chat_received', (data) => {
      console.log('chat_received', data);
      const newMessage = {
        ...data.message,
        timestamp: new Date(data.message.createdAt),
      };

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages, newMessage];
        return updatedMessages.sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        );
      });

      socket.current.emit('acknowledge_message', {
        messageId: data.message.id,
      });

      if (!isTabActive) {
        sendNotification(data.message.text);
      }
    });

    socket.current.on('chat_sent', (data) => {
      console.log('chat_sent event received:', data);
    });

    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    requestNotificationPermission();

    return () => {
      socket.current.disconnect();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await axios.post('/users/messages', {
        ConversationId,
        page: chatPage,
        size: 10,
      });

      const newMessages = response.data;
      setMessages((prevMessages) => [...newMessages.reverse(), ...prevMessages]);

      setTimeout(() => {
        scrollToBottom();
      }, 200);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  useEffect(() => {
    if (ConversationId) {
      fetchMessages();
    }
  }, [ConversationId, chatPage]);

  const requestNotificationPermission = () => {
    if (
      Notification.permission !== 'granted' &&
      Notification.permission !== 'denied'
    ) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted');
        }
      });
    }
  };

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [selectedImage]);

  const sendNotification = (message) => {
    if (Notification.permission === 'granted') {
      const notificationOptions = {
        body: message,
        icon: mena,
        requireInteraction: false,
        silent: true,
      };

      const notification = new Notification('New Message', notificationOptions);

      const audio = new Audio(notif);
      audio.play();

      setTimeout(() => {
        notification.close();
      }, 3000);

      notification.onclick = () => {
        window.focus();
      };
    }
  };

  const handleSendMessage = () => {
    const text = textareaRef.current.value;

    if (!text.trim() && !imagePath) return;

    const tempId = Date.now();
    const newMessage = {
      id: tempId,
      senderId: senderID,
      text,
      image: imagePath,
      createdAt: new Date().toISOString(),
      timestamp: new Date().toISOString(),
      isSentByUser: true,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);

    socket.current.emit(
      'chat_message',
      {
        text,
        image: imagePath,
        ReceiverId: ConversationId, // Assuming conversationId is the same as the receiver
        ConversationId,
      },
      (acknowledgment) => {
        if (acknowledgment.status === 'received') {
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === tempId
                ? { ...msg, id: acknowledgment.messageId, status: 'sent' }
                : msg
            )
          );
        }
      }
    );

    textareaRef.current.value = null;
    setSelectedImage(null);
    setImagePath(null);
    autosize.destroy(textareaRef.current);
    scrollToBottom();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      handleSendMessage();
    }
  };

  return (
    <div className='min-h-screen bg-[#F6F2EF] dark:bg-[#110130] font-arabic flex flex-col' dir='rtl'>
      <div className='sticky mb-5 top-0 bg-[#F6F2EF] dark:bg-[#110130] z-10'>
        <MainHeader showRefresh={true} />
      </div>

      <div className='flex-1 overflow-y-auto px-4 space-y-4'>
        {messages.length === 0 && (
          <div className='flex-1 flex items-center justify-center'>
            <h2>No messages yet</h2>
          </div>
        )}

        {messages.map((msg, index) => (
          <div key={index} className={`flex ${msg.senderId == senderID ? 'justify-start' : 'justify-end'}`}>
            <div className={`flex items-center relative gap-8 p-4 max-w-[80%] rounded-3xl shadow-md ${msg.senderId == senderID ? 'bg-[#4C00FF] text-white' : 'bg-white text-black'}`}>
              <p className='text-sm mb-1'>{msg.text}</p>
              <div className={`absolute bottom-[-6px] w-0 h-0 border-[12px] ${msg.senderId == senderID ? 'right-0 border-transparent border-t-[#4C00FF] border-r-[#4C00FF] rotate-[-36deg]' : 'left-0 border-transparent border-t-white border-l-white rotate-[36deg]'}`} />
            </div>
          </div>
        ))}

        <div ref={bottomRef} />
      </div>

      <div className='p-4 bg-transparent sticky bottom-0 flex items-center'>
        <div className={`cursor-pointer ml-2 bg-[#4C00FF] rounded-full p-2 ${isSendDisabled ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={!isSendDisabled ? handleSendMessage : null}>
          <img src={sendIcon} alt='Send' className='h-10 w-10 p-1' />
        </div>
        <div className='flex items-center bg-white border-[1px] border-[#fff] dark:bg-[#110130] rounded-full p-2 shadow-md flex-1'>
          <textarea ref={textareaRef} rows='1' onKeyDown={handleKeyDown} placeholder='اكتب استفسارك' className='flex-1 bg-transparent border-none !text-right focus:ring-0 focus:outline-none text-black dark:text-white px-4 resize-none text-lg max-h-[80px] overflow-y-auto' />
        </div>
      </div>

      {showCopiedMessage && (
        <div className='fixed bottom-28 left-1/2 transform -translate-x-1/2 bg-[#969696] text-white px-4 py-2 rounded-full'>
          تم النسخ إلى الحافظة
        </div>
      )}
    </div>
  );
};

export default ChatFromNotification;
