import { useNavigate } from 'react-router-dom';
import MainHeader from '../../components/Header/MainHeader';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';

const ChatList = () => {
  const navigate = useNavigate();

  const messages = [
    {
      id: 1,
      time: 'اليوم',
      text: 'مرحبا عندي مشكلة بالركبة ماتني من امشي في المرتفعات علما انا اعاني من مرض السكر',
    },
    {
      id: 2,
      time: 'قبل اسبوع',
      text: 'اعاني من الم في المفاصل خصوصا بعد المشي لمسافة احس ب الم قوي ومرات يغمى عليه. اني اعاني من الضغط والسكر ممكن تشخيص للحالة',
    },
    {
      id: 3,
      time: 'قبل شهر',
      text: 'مرحبا عندي مشكلة بالركبة ماتني من امشي في المرتفعات علما انا اعاني من مرض السكر',
    },
  ];

  const openChat = () => {
    navigate('/chat');
  };

  return (
    <div className='min-h-screen bg-[#F6F2EF] dark:bg-[#110130] font-arabic'>
      <MainHeader showRefresh={false} />
      <div className='px-4 py-6 space-y-4'>
        {messages.map((message) => (
          <div key={message.id}>
            <div className='text-right text-[#666666] dark:text-[#FFFFFF] mb-2 mr-3'>
              {message.time}
            </div>
            <div className='relative bg-white dark:bg-chat-list-dark-bg rounded-[19px] p-4 shadow-md pb-4'>
              <div
                onClick={openChat}
                className='text-right text-black dark:text-white break-words whitespace-pre-wrap w-[90%] cursor-pointer'>
                {message.text}
              </div>
              <ChevronLeftIcon
                onClick={openChat}
                className='absolute left-4 top-1/2 transform -translate-y-1/2 h-6 w-6 text-black dark:text-white cursor-pointer'
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatList;
