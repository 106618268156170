import React from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import PostInteraction from './Comments';
import dayjs from 'dayjs';
import 'dayjs/locale/ar';
import relativeTime from 'dayjs/plugin/relativeTime';
import profile_1pic from '../../assets/icons/profile.png';

const Post = ({
  ID,
  profilePic,
  theme,
  userName,
  timeAgo,
  postImage,
  title,
  content,
  likes,
  liked,
  comments,
}) => {
  dayjs.locale('ar');
  dayjs.extend(relativeTime);
  const profileImageUrl = profilePic || profile_1pic;

  const timeAgoFormatted = dayjs(timeAgo).fromNow();

  return (
    <div className='p-5 md:p-8 bg-[#F6F2EF] dark:bg-[#110130]'>
      <div className='flex items-center mb-6 justify-between'>
        <div className='flex items-center gap-3'>
          <img
            src={profileImageUrl}
            alt='Profile'
            className='w-12 h-12 md:w-16 md:h-16 rounded-full mr-3'
          />
          <div>
            <h3 className='text-lg md:text-xl font-bold text-[#110130] dark:text-white'>
              {userName}
            </h3>
            <p className='text-[#616161] text-sm md:text-base'>
              {timeAgoFormatted}
            </p>
          </div>
        </div>
        <EllipsisVerticalIcon className='h-6 w-6 md:h-8 md:w-8 text-[#4C00FF] cursor-pointer' />
      </div>

      {postImage && (
        <img
          src={postImage}
          alt='Post'
          className='w-full h-[236px] md:h-[420px] object-cover rounded-lg mb-4'
        />
      )}
      <h3 className='text-lg md:text-xl font-bold text-[#110130] dark:text-white mb-2'>
        {title}
      </h3>
      <p className='text-[#110130] dark:text-white text-sm md:text-lg'>
        {content}
      </p>
      <PostInteraction
        liked={liked}
        ID={ID}
        userName={userName}
        likes={likes}
        theme={theme}
        comments={comments}
      />
    </div>
  );
};

export default Post;
