import { useNavigate, useLocation } from 'react-router-dom';
import logo_light from '../../assets/logos/logo-chat-light.svg';
import logo_dark from '../../assets/logos/logo-chat-dark.svg';
import {
  Bars3Icon,
  XMarkIcon,
  ArrowLeftIcon,
  PhoneIcon,
} from '@heroicons/react/24/solid';
import refreshIcon from '../../assets/icons/refresh.svg';
import refreshwhite from '../../assets/icons/refresh-white.svg';
import { useState, useEffect, useRef } from 'react';
import labo_logo from '../../assets/logos/hadeel.jpeg';
import PrivacyPolicyModal from '../PrivacyPolicy/PrivacyPolicy';

const ChatHeader = ({ showRefresh, onRefresh, showBack = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [theme, setTheme] = useState('light');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [linkName, setLinkName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [activeMenu, setActiveMenu] = useState('');
  const menuRef = useRef(null);

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath === '/') {
      setActiveMenu('الرئيسية');
    } else if (currentPath === '/chat') {
      setActiveMenu('أسال الذكاء الاصطناعي');
    } else {
      setActiveMenu('الرئيسية');
    }
  }, [location.pathname]);

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
    setIsMenuOpen(false);
    navigate(menu === 'الرئيسية' ? '/' : '/chat');
  };
  const openModal = (name) => {
    setIsModalOpen(true);
    setLinkName(name);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openPhone = () => {
    // setIsModalOpen(false);
  };

  return (
    <div
      className='relative pt-5 pb-3 px-4 flex justify-around bg-[#f6f2ef] dark:bg-[#110130] items-center'
      dir='rtl'>
      <div className='flex items-center'>
        <PhoneIcon
          className='h-6 w-6 text-black dark:text-white cursor-pointer'
          onClick={() => {
            let phoneNumber = '7700002352';
            if (phoneNumber && !phoneNumber.startsWith('0')) {
              phoneNumber = `0${phoneNumber}`;
            }
            window.location.href = `tel:${phoneNumber}`;
          }}
        />
      </div>

      <div className=' text-center z-30'>
        {showBack && (
          <ArrowLeftIcon
            className='absolute left-4 h-6 w-6 text-black dark:text-white cursor-pointer'
            onClick={() => navigate(-1)}
          />
        )}
        <div className=' font-arabic'>
          <p className='text-[#4C00FF] text-lg font-black'>
            مختبر الهديل الطبي
          </p>
        </div>
        <div className=' font-arabic'>
          <p className='text-lg font-semibold'>للتحليلات المرضية المتقدمة</p>
        </div>
      </div>
      <div className=' text-center z-30'>
        <div className='relative'>
          <img src={labo_logo} alt='logo' className='w-14 h-14 rounded-full' />
        </div>
      </div>
      <PrivacyPolicyModal
        linkName={linkName}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </div>
  );
};

export default ChatHeader;
