import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

// Reusable function to handle button clicks and log the event
export const LogButtonClick = (buttonName, pageLocation) => {
  logEvent(analytics, 'button_click', {
    button_name: buttonName,
    page_location: pageLocation || window.location.pathname, // Use the current route if not provided
  });
};
