import React, { useState, useEffect, useCallback } from 'react';
import {
  ChevronRightIcon,
  EllipsisVerticalIcon,
  ClockIcon,
  MapPinIcon,
  PhoneIcon,
} from '@heroicons/react/24/solid';
import chat from '../../assets/icons/chat.svg';
import follow_light from '../../assets/icons/follow.svg';
import follow_dark from '../../assets/icons/follow_dark.svg';
import background_svg from '../../assets/post/background_svg.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Post from '../../components/Post/Post';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../utilities/axios';
import ProfileSlider from '../../components/Slider/ProfileSlider';
import useRequireAuth from '../../Hooks/useRequireAuth';
import DropdownMenu from '../../components/Dropdown/DropdownMenu';
import MainHeader from '../../components/Header/MainHeader';
import Footer from '../../components/Footer/Footer';

const User = () => {
  const isAuthenticated = useRequireAuth();
  const [theme, setTheme] = useState('light');
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1); // Track the current page
  const [hasMorePosts, setHasMorePosts] = useState(true); // Check if more posts are available
  const [isFetching, setIsFetching] = useState(false); // Flag for preventing multiple fetches
  const [profile, setProfile] = useState({});
  const [isFollowing, setIsFollowing] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  // Fetch initial data when the component mounts
  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`/users/profile?id=${id}`)
        .then((response) => {
          setProfile(response.data);
          setIsFollowing(response.data.following);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
      loadPosts(page); // Fetch the first page of posts
    }
  }, [id]);

  // Function to load posts based on page number
  const loadPosts = async (pageNumber) => {
    if (isFetching) return; // Prevent new requests while already fetching

    setIsFetching(true); // Set fetching flag before starting the request

    try {
      const response = await axios.get(
        `/posts?page=${pageNumber}&size=10&UserId=${id}`
      );
      if (response.data.length > 0) {
        setPosts((prevPosts) => [...prevPosts, ...response.data]);
      } else {
        setHasMorePosts(false); // No more posts available
      }
    } catch (error) {
      console.error('Error loading posts:', error);
    } finally {
      setIsFetching(false); // Clear fetching flag after the request is done
    }
  };

  // Infinite scroll function to check when the user reaches the bottom of the page
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 1 &&
      !isFetching && // Make sure we are not currently fetching data
      hasMorePosts // Ensure there are more posts to load
    ) {
      // Increase the page number when 500px from bottom, but only if no data is being fetched
      setPage((prevPage) => prevPage + 1);

      console.log('here the page increased', page);
    }
  }, [isFetching, hasMorePosts]);

  // Fetch more posts whenever the page number changes
  useEffect(() => {
    if (page > 1 && !isFetching && hasMorePosts) {
      loadPosts(page); // Trigger data fetching for the new page
    }
  }, [page]);

  // Set up the scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const follow = async () => {
    if (!isAuthenticated) return navigate('/new-account');
    if (!profile.canFollow) return;

    try {
      const response = await axios.get(`/follows?UserId=${profile.id}`);
      if (response.status === 200) {
        setIsFollowing(!isFollowing);
      }
    } catch (error) {
      console.error('Error following user:', error);
    }
  };

  const openChat = () => {
    // if (!isAuthenticated) return navigate('/new-account');
    navigate(`/post-chat?UserId=${profile.id}`);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const images = [
    profile.coverImage,
    profile.profileImage,
    profile.mainImage,
  ].filter(Boolean);

  return (
    <div className={`font-arabic ${theme === 'dark' ? 'dark' : ''} relative`}>
      <MainHeader />
      {loading ? (
        <div className='h-[80vh] inset-0 flex items-center justify-center bg-[#F6F2EF] dark:bg-[#110130] bg-opacity-50'>
          <div
            className={`${
              theme === 'dark' ? 'user_loader_dark' : 'user_loader_light'
            }`}></div>
        </div>
      ) : (
        <>
          <img
            src={background_svg}
            alt='background_svg'
            className='absolute left-0 top-0 w-[50%] md:w-[28%] z-0'
          />
          <div className='bg-[#F6F2EF] dark:bg-[#110130] min-h-screen rtl'>
            <div className='flex justify-between items-center p-4 md:p-6'>
              <ChevronRightIcon className='h-6 w-6 md:h-8 md:w-8 text-[#110130] dark:text-white cursor-pointer' />
              <div className='text-center'>
                <h1
                  onClick={toggleTheme}
                  className='text-2xl md:text-3xl font-bold text-[#110130] dark:text-white'>
                  {profile.name || 'مركز الحياة الذهني'}
                </h1>
              </div>
              <div className='relative'>
                <EllipsisVerticalIcon
                  className='h-6 w-6 md:h-8 md:w-8 text-[#110130] dark:text-white cursor-pointer'
                  onClick={toggleDropdown}
                />
                {isDropdownOpen && (
                  <DropdownMenu
                    onToggleTheme={toggleTheme}
                    theme={theme}
                    closeDropdown={closeDropdown}
                  />
                )}
              </div>
            </div>

            <div className='flex justify-center gap-6 items-center bg-transparent dark:bg-transparent p-4 md:p-6 rounded-lg'>
              <div className='flex items-center space-x-reverse gap-4 md:gap-4'>
                <p className='text-xl md:text-2xl font-bold text-[#110130] dark:text-white'>
                  {profile.postsNumber || 0}
                </p>
                <p className='text-[#110130] dark:text-white text-base md:text-lg'>
                  سؤال
                </p>
              </div>
              <div className='h-8 md:h-10 border-[2px]  border-[#110130] dark:border-white'></div>
              <div className='flex items-center space-x-reverse gap-4 md:gap-4'>
                <p className='text-xl md:text-2xl font-bold text-[#110130] dark:text-white'>
                  {profile.followersNumber || 0}
                </p>
                <p className='text-gray-600 dark:text-gray-400 text-base md:text-lg'>
                  متابع
                </p>
              </div>
            </div>

            <div className='bg-[#F6F2EF] dark:bg-[#110130] shadow-top-only-light rounded-t-[30px] dark:shadow-top-only-dark'>
              <ProfileSlider userId={profile.id} />
              <div className='p-5 md:p-8 mt-5 bg-[#F6F2EF] dark:bg-[#110130]'>
                <h2 className='text-xl md:text-2xl font-bold leading-5 mb-4 text-[#4C00FF] dark:text-[#4C00FF]'>
                  نبذة عن مختبرنا
                </h2>
                <p className='text-[#110130] text-sm md:text-base dark:text-[#F6F2EF] mt-2'>
                  {profile.bio ||
                    'نحن في مختبر الحياة الطبي نسعى لتقديم أفضل الخدمات وأجود الفحوصات بأفضل الأجهزة الطبية المستخدمة.. نحن ملتزمون بالجودة والابتكار في كل ما نقدمه.'}
                </p>
                <div className='mt-4 space-y-3'>
                  <div className='flex items-center'>
                    <div className='bg-[#4C00FF14] dark:bg-[#4C00FF14] bg-opacity-20 rounded-[8px] p-2 ml-2'>
                      <ClockIcon className='h-4 w-4 md:h-6 md:w-6 text-[#4C00FF] dark:text-[#4C00FF]' />
                    </div>
                    <p className='text-[#110130] text-sm md:text-base dark:text-[#F6F2EF]'>
                      {profile.workHours ||
                        'يفتح يومياً من الساعة 08:00 صباحاً إلى الساعة 08:00 مساءً.'}
                    </p>
                  </div>
                  <div className='flex items-center'>
                    <div className='bg-[#4C00FF14] dark:bg-[#4C00FF14] bg-opacity-20 rounded-[8px] p-2 ml-2'>
                      <MapPinIcon className='h-4 w-4 md:h-6 md:w-6 text-[#4C00FF] dark:text-[#4C00FF]' />
                    </div>
                    <p className='text-[#110130] text-sm md:text-base dark:text-[#F6F2EF]'>
                      {profile.address || 'سوريا - حلب - شارع سعد الله الجابري'}
                    </p>
                  </div>
                  <div className='flex items-center'>
                    <div className='bg-[#4C00FF14] dark:bg-[#4C00FF14] bg-opacity-20 rounded-[8px] p-2 ml-2'>
                      <PhoneIcon className='h-4 w-4 md:h-6 md:w-6 text-[#4C00FF] dark:text-[#4C00FF]' />
                    </div>
                    <p
                      onClick={() => {
                        let phoneNumber = profile.mobile || '';
                        if (phoneNumber && !phoneNumber.startsWith('0')) {
                          phoneNumber = `0${phoneNumber}`;
                        }
                        window.location.href = `tel:${phoneNumber}`;
                      }}
                      className='text-[#110130] hover:text-[#4c00ff] cursor-pointer text-sm md:text-base dark:hover:text-[#4c00ff] dark:text-[#F6F2EF]'>
                      {profile.mobile && !profile.mobile.startsWith('0')
                        ? `0${profile.mobile}`
                        : profile.mobile || ''}
                    </p>
                  </div>
                </div>
                <div className='mt-6 flex justify-around'>
                  {profile.canFollow ? (
                    <button
                      onClick={follow}
                      hidden={!profile.canFollow}
                      disabled={!profile.canFollow}
                      className={`border-2 border-[#4C00FF] dark:border-white text-[#4C00FF] dark:text-[#fff] w-[146px] h-[40px] md:w-[180px] md:h-[48px] rounded-[8px] flex items-center justify-center ${
                        !profile.canFollow
                          ? 'opacity-50 cursor-not-allowed'
                          : ''
                      }`}>
                      <img
                        src={theme === 'dark' ? follow_light : follow_dark}
                        alt='follow'
                        className='h-5 w-5 md:h-6 md:w-6 cursor-pointer ml-2'
                      />
                      {isFollowing ? 'إلغاء المتابعة' : 'متابعة'}
                    </button>
                  ) : (
                    <></>
                  )}

                  {profile.canChat ? (
                    <button
                      onClick={openChat}
                      disabled={!profile.canChat}
                      className={`bg-[#4C00FF] text-white w-[146px] h-[40px] md:w-[180px] md:h-[48px] rounded-[8px] flex items-center justify-center ${
                        !profile.canChat ? 'opacity-50 cursor-not-allowed' : ''
                      }`}>
                      <img
                        src={chat}
                        alt='Chat'
                        className='h-4 w-4 md:h-5 md:w-5 cursor-pointer ml-2'
                      />
                      إرسال رسالة
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            {posts.map((post) => (
              <Post
                key={post.id}
                ID={post.id}
                profilePic={post.User.profileImage}
                theme={theme}
                userName={post.User.name}
                timeAgo={post.createdAt}
                postImage={post.image}
                title={post.title}
                content={post.content}
                liked={post.liked}
                likes={post.Likes}
                comments={post.Comments}
              />
            ))}

            {profile.canChat ? (
              <div className='fixed bottom-8 right-8'>
                <div className='relative'>
                  <div className='absolute inset-0 bg-[#4C00FF1A] rounded-full animate-ping-slow opacity-75'></div>
                  <button
                    onClick={openChat}
                    className='relative bg-[#4C00FF] text-white p-7 rounded-full flex items-center justify-center shadow-lg focus:outline-none'>
                    <img
                      src={chat}
                      alt='Chat'
                      className='h-12 w-12 cursor-pointer'
                    />
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
      <Footer />
    </div>
  );
};

export default User;
