import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../Hooks/authContext';
import Footer from '../components/Footer/Footer';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to='/login' />;
  }

  return (
    <>
      {children}
      {/* <Footer /> */}
    </>
  );
};

export default ProtectedRoute;
