import React, { useState, useEffect } from 'react';
import axios from '../../utilities/axios';
import { XCircleIcon } from '@heroicons/react/24/solid';

const PrivacyPolicyModal = ({ isOpen, onClose, linkName }) => {
  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isOpen) {
      axios
        .get('/privacypolicy')
        .then((response) => {
          const modifiedContent = response.data.replace(
            /####/g,
            '<div style="margin: 20px 0;"><hr /></div>'
          );
          setPrivacyPolicy(modifiedContent);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching privacy policy:', error);
          setLoading(false);
        });
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
      <div className='relative bg-white dark:bg-gray-800 p-6 md:p-8 rounded-lg w-4/5 max-w-4xl shadow-lg h-[60vh] md:h-[80vh] overflow-hidden'>
        <XCircleIcon
          onClick={onClose}
          className='absolute right-[15px] top-[15px] cursor-pointer text-black bg-white rounded-full h-8 w-8'
        />
        {loading ? (
          <p className='text-center text-gray-700 dark:text-gray-300'>
            Loading...
          </p>
        ) : (
          <div className='overflow-y-auto p-4 md:p-6 h-full custom-scrollbar'>
            <h2 className=' top-0 bg-white dark:bg-gray-800 text-2xl text-center font-semibold mb-8 text-gray-900 dark:text-white z-10'>
              {linkName}
            </h2>
            <div
              dangerouslySetInnerHTML={{ __html: privacyPolicy }}
              className='text-gray-800 dark:text-gray-300 space-y-4'
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PrivacyPolicyModal;
