import { useNavigate, useLocation } from 'react-router-dom';

import { useState, useEffect } from 'react';
import powerd from '../../assets/logos/powerd.svg';
import logo_light from '../../assets/logos/logo-chat-light.svg';
import logo_dark from '../../assets/logos/logo-chat-dark.svg';
const DoctorFooter = () => {
  const navigate = useNavigate();

  const [theme, setTheme] = useState('light');

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };
  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);
  const goChat = () => {
    navigate('/post-chat?UserId=39');
  };
  return (
    <footer
      className=' dark:bg-[#110130] font-arabic pt-6 pb-1 relative z-40'
      dir='rtl'>
      <div className='max-w-screen-xl mx-auto px-4 flex justify-between md:flex-row-reverse flex-col-reverse md:gap-0 gap-3 items-center'>
        <div className='relative'>
          <p className='text-[#4c00ff] w-full text-sm dark:text-white absolute -top-5 -left-1  '>
            Powered by
          </p>
          <img
            src={theme === 'light' ? logo_light : logo_dark}
            onClick={toggleTheme}
            alt='Mena Logo'
            className='inline-block h-[28px] w-[80px] cursor-pointer ml-2'
          />
        </div>
      </div>
      <div className='absolute bottom-0 left-0 right-0 h-1 bg-[#5d1dd0]'></div>
    </footer>
  );
};

export default DoctorFooter;
