import { createContext, useState, useContext } from 'react';

const LabelContext = createContext();

export const LabelProvider = ({ children }) => {
  const [label, setLabel] = useState('');

  const value = { label, setLabel };

  return (
    <LabelContext.Provider value={value}>{children}</LabelContext.Provider>
  );
};

export const useLabel = () => useContext(LabelContext);
