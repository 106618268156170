import { useState } from 'react';
import logo_dark from '../../assets/logos/logo-chat-dark.svg';
import location from '../../assets/logos/location.svg';
import WhatsApp_icon from '../../assets/logos/WhatsApp_icon.svg';
import single_mena from '../../assets/logos/single_mena.svg';
import icon_mena from '../../assets/logos/icon_mena.svg';
import massenger from '../../assets/logos/massenger.svg';
import { useTranslation } from 'react-i18next';
import PrivacyPolicyModal from '../../components/PrivacyPolicy/PrivacyPolicy';

const MedicalLabComponent = () => {
  const { t, i18n } = useTranslation();
  const [linkName, setLinkName] = useState('سياسة الخصوصية والاحكام');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div
      className='min-h-screen w-full bg-[#001839] text-white px-3 pt-8 pb-6 flex flex-col items-center'
      dir='rtl'>
      <div className='text-center flex justify-center items-center flex-col mb-10'>
        <p className='text-xs mb-2 text-white'>POWERED BY</p>
        <img src={logo_dark} className='h-10 w-28' alt='logo' />
      </div>

      <div className='w-full h-[410px] flex items-center justify-center mb-[2rem]'>
        <div className='h-full w-full md:w-[60%] p-6 space-y-6 bg-login-dark-bg rounded-3xl shadow-md'>
          <img src={location} className='h-10 w-full' alt='location' />
          <h1 className='text-2xl font-arabic mt-12  text-center text-white'>
            مختبر الدولي في البصرة{' '}
          </h1>
          <div className='flex flex-col mt-8 items-center gap-5 justify-center'>
            <h1 className='text-4xl font-arabic  text-center text-white'>
              أهلا بيكم
            </h1>
            <p className='font-arabic text-[15px] text-center text-white uniform-line-height'>
              اني الدكتورة مينا من مختبر الدولي في البصرة
              <hr className=' border-transparent' />
              اكتب استفسارك او سؤالك عن المختبر والعروض وراح أجاوبكم ب اسرع وقت
            </p>
          </div>
          <div className='flex flex-col items-center justify-center w-full space-y-3'>
            <button
              id='whatsapp-button'
              onClick={() => {
                window.open('https://wa.me/9647716222206', '_blank');
                fbq('track', 'WhatsAppClick1'); // Facebook Pixel tracking for WhatsApp click
                LogButtonClick(
                  'WhatsAppClick Button for basraLab',
                  window.location.pathname
                ); // Log event with Firebase
              }}>
              <div className='cursor-pointer hover:bg-slate-400 hover:text-black flex items-center gap-3 border-[1px] px-3 py-2 rounded-[8px] border-white'>
                <img
                  src={WhatsApp_icon}
                  className='whatsapp-flashing-image'
                  alt='whatsapp'
                />
                <p className='text-xl'>اضغط هنا لأرسال رسالة واتساب </p>
              </div>
            </button>

            <button
              id='messenger-button'
              onClick={() => {
                window.open('https://m.me/1325014820984795', '_blank');
                fbq('track', 'MessengerClick1'); // Facebook Pixel tracking for Messenger click
                LogButtonClick(
                  'MessengerClick Button for basraLab',
                  window.location.pathname
                ); // Log event with Firebase
              }}>
              <div className='cursor-pointer hover:bg-slate-400 hover:text-black flex items-center gap-3 border-[1px] px-3 py-2 rounded-[8px] border-white'>
                <img
                  src={massenger}
                  className='massenger-flashing-image w-7'
                  alt='messenger'
                />
                <p className='text-xl'>اضغط هنا لأرسال رسالة ماسنجر</p>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className='mt-8 w-full'>
        <h3 className='text-2xl mb-8'>الأسئلة الشائعة</h3>
        <div className='space-y-4 h-[60vh] overflow-y-auto'>
          <div className='bg-[#F1F5FB] p-4 rounded-lg flex flex-col items-start space-x-3 shadow-md'>
            <div className='w-full flex items-center justify-end'>
              <div className='flex flex-col items-center justify-center'>
                <img src={single_mena} alt='mena' className='w-12 h-6' />
                <span className='text-xs line tracking-widest  text-gray-500'>
                  منذ 2 دقيقة{' '}
                </span>
              </div>
              <img src={icon_mena} alt='mena icon' className='w-8 h-8 mr-2' />
            </div>

            <div className='flex-1'>
              <p className='mt-1 text-sm text-[#110130]'>
                عندكم فحص شامل ؟
                <br />
                <span className='text-[#4C00FF]'>
                  إي نعم موجود اكو الفحص الشامل الأساسي بسعر 35 الف والشامل
                  المتكامل بسعر 49 الف
                </span>
              </p>
            </div>
          </div>
          <div className='bg-[#F1F5FB] p-4 rounded-lg flex flex-col items-start space-x-3 shadow-md'>
            <div className='w-full flex items-center justify-end'>
              <div className='flex flex-col items-center justify-center'>
                <img src={single_mena} alt='mena' className='w-12 h-6' />
                <span className='text-xs line tracking-widest  text-gray-500'>
                  منذ دقيقة
                </span>
              </div>
              <img src={icon_mena} alt='mena icon' className='w-8 h-8 mr-2' />
            </div>

            <div className='flex-1'>
              <p className='mt-1 text-sm text-[#110130]'>
                اشكد سعر فحص الفيتامينات والعناصر ؟
                <br />
                <span className='text-[#4C00FF]'>
                  اهلا بيكم .. نعم موجود عليه عرض ٥٠ بالميه خصم بسعر ٥٩ بدل ١٢٠
                  الف
                </span>
              </p>
            </div>
          </div>
          <div className='bg-[#F1F5FB] p-4 rounded-lg flex flex-col items-start space-x-3 shadow-md'>
            <div className='w-full flex items-center justify-end'>
              <div className='flex flex-col items-center justify-center'>
                <img src={single_mena} alt='mena' className='w-12 h-6' />
                <span className='text-xs line tracking-widest  text-gray-500'>
                  منذ 4 دقائق
                </span>
              </div>
              <img src={icon_mena} alt='mena icon' className='w-8 h-8 mr-2' />
            </div>

            <div className='flex-1'>
              <p className='mt-1 text-sm text-[#110130]'>
                مرحبا عندي تساقط شعر وجربت اكثر من شامبو
                <br />
                <span className='text-[#4C00FF]'>
                  اهلا بيكم .. جربتي تسوين فحص تساقط الشعر ؟ جدا يفيدكم لان راح
                  يبين السبب الحقيقي
                </span>
              </p>
            </div>
          </div>
          <div className='bg-[#F1F5FB] p-4 rounded-lg flex flex-col items-start space-x-3 shadow-md'>
            <div className='w-full flex items-center justify-end'>
              <div className='flex flex-col items-center justify-center'>
                <img src={single_mena} alt='mena' className='w-12 h-6' />
                <span className='text-xs line tracking-widest  text-gray-500'>
                  منذ 20 دقيقة
                </span>
              </div>
              <img src={icon_mena} alt='mena icon' className='w-8 h-8 mr-2' />
            </div>

            <div className='flex-1'>
              <p className='mt-1 text-sm text-[#110130]'>
                عندي خمول وتعب بشكل كبير شنو الفحوصات الي اسويها ؟
                <br />
                <span className='text-[#4C00FF]'>
                  سوي الفحص الفيتامين دي والغدة الدرقية
                </span>
              </p>
            </div>
          </div>
          <div className='bg-[#F1F5FB] p-4 rounded-lg flex flex-col items-start space-x-3 shadow-md'>
            <div className='w-full flex items-center justify-end'>
              <div className='flex flex-col items-center justify-center'>
                <img src={single_mena} alt='mena' className='w-12 h-6' />
                <span className='text-xs line tracking-widest  text-gray-500'>
                  منذ ساعة
                </span>
              </div>
              <img src={icon_mena} alt='mena icon' className='w-8 h-8 mr-2' />
            </div>

            <div className='flex-1'>
              <p className='mt-1 text-sm text-[#110130]'>
                عفية وزني ثابت مجاي ينزل شنو الفحوصات الي اسويها ؟ <br />
                <span className='text-[#4C00FF]'>
                  اهلا بيكم سوي الفحص الخاص بثبات الوزن تفضلي يمنا وتدللون
                </span>
              </p>
            </div>
          </div>
          <div className='bg-[#F1F5FB] p-4 rounded-lg flex flex-col items-start space-x-3 shadow-md'>
            <div className='w-full flex items-center justify-end'>
              <div className='flex flex-col items-center justify-center'>
                <img src={single_mena} alt='mena' className='w-12 h-6' />
                <span className='text-xs line tracking-widest  text-gray-500'>
                  منذ نصف ساعة{' '}
                </span>
              </div>
              <img src={icon_mena} alt='mena icon' className='w-8 h-8 mr-2' />
            </div>

            <div className='flex-1'>
              <p className='mt-1 text-sm text-[#110130]'>
                مرحبا عيوني عندي خربطه هرمونات شنو اسوي فحوصات <br />
                <span className='text-[#4C00FF]'>
                  اهلا بيكم موجود عندنا فحص الهرمونات الكامل ب ٥٩ الف ولازم
                  تسوين الفحوصات ثاني يوم الدورة{' '}
                </span>
              </p>
            </div>
          </div>
          <div className='bg-[#F1F5FB] p-4 rounded-lg flex flex-col items-start space-x-3 shadow-md'>
            <div className='w-full flex items-center justify-end'>
              <div className='flex flex-col items-center justify-center'>
                <img src={single_mena} alt='mena' className='w-12 h-6' />
                <span className='text-xs line tracking-widest  text-gray-500'>
                  منذ ساعة
                </span>
              </div>
              <img src={icon_mena} alt='mena icon' className='w-8 h-8 mr-2' />
            </div>

            <div className='flex-1'>
              <p className='mt-1 text-sm text-[#110130]'>
                الفحص الشامل يحتاج صيام ٢٤ ساعه ؟ <br />
                <span className='text-[#4C00FF]'>
                  اهلا بيكم .. لا عيوني يحتاج فقط الانقطاع عن الطعام لمدة ٨
                  ساعات فقط .. عادي اشربو مي
                </span>
              </p>
            </div>
          </div>
          <div className='bg-[#F1F5FB] p-4 rounded-lg flex flex-col items-start space-x-3 shadow-md'>
            <div className='w-full flex items-center justify-end'>
              <div className='flex flex-col items-center justify-center'>
                <img src={single_mena} alt='mena' className='w-12 h-6' />
                <span className='text-xs line tracking-widest  text-gray-500'>
                  منذ 12 ساعة
                </span>
              </div>
              <img src={icon_mena} alt='mena icon' className='w-8 h-8 mr-2' />
            </div>

            <div className='flex-1'>
              <p className='mt-1 text-sm text-[#110130]'>
                وين عنوانكم بلا زحمه فرع الموصل <br />
                <span className='text-[#4C00FF]'>
                  اهلا بيكم .. الموصل تقاطع المصارف قرب اثاث بيلونا{' '}
                </span>
              </p>
            </div>
          </div>
          <div className='bg-[#F1F5FB] p-4 rounded-lg flex flex-col items-start space-x-3 shadow-md'>
            <div className='w-full flex items-center justify-end'>
              <div className='flex flex-col items-center justify-center'>
                <img src={single_mena} alt='mena' className='w-12 h-6' />
                <span className='text-xs line tracking-widest  text-gray-500'>
                  منذ 4 ساعة
                </span>
              </div>
              <img src={icon_mena} alt='mena icon' className='w-8 h-8 mr-2' />
            </div>

            <div className='flex-1'>
              <p className='mt-1 text-sm text-[#110130]'>
                بلا زحمه عندكم فحوصات للرياضيين؟ <br />
                <span className='text-[#4C00FF]'>
                  اهلا بيكم نعم موجودة تفضلو يمنا وتدللون
                </span>
              </p>
            </div>
          </div>
          <div className='bg-[#F1F5FB] p-4 rounded-lg flex flex-col items-start space-x-3 shadow-md'>
            <div className='w-full flex items-center justify-end'>
              <div className='flex flex-col items-center justify-center'>
                <img src={single_mena} alt='mena' className='w-12 h-6' />
                <span className='text-xs line tracking-widest  text-gray-500'>
                  منذ 2 ساعة
                </span>
              </div>
              <img src={icon_mena} alt='mena icon' className='w-8 h-8 mr-2' />
            </div>

            <div className='flex-1'>
              <p className='mt-1 text-sm text-[#110130]'>
                اريد اسوي فحوصات لان بشرتي تعبانه ... <br />
                <span className='text-[#4C00FF]'>
                  اهلا بيكم .. موجود فحص الفيتامينات والعناصر بسعر ٥٩ الف
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='w-full text-center mt-8 text-gray-400'>
        <div className='flex items-center justify-center gap-2 '>
          <img
            src={logo_dark}
            alt='Mena Logo'
            className='inline-block h-[52px] w-[100px] md:w-[120px] cursor-pointer  md:ml-10'
          />
          <p className='  text-sm  text-white'>powered by </p>
        </div>
      </div>
      <PrivacyPolicyModal
        linkName={linkName}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
      <p
        onClick={() => openModal('الشروط والأحكام')}
        className='text-sm mb-2 text-white cursor-pointer hover:text-[#c2d5ef] mt-3'>
        سياسة الخصوصية والاحكام
      </p>
    </div>
  );
};

export default MedicalLabComponent;
