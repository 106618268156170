import { useState } from 'react';
import axios from '../../utilities/axios';
import ReactPixel from 'react-facebook-pixel';
const Modal = ({ showModal, setShowModal, userId }) => {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [day, setDay] = useState(''); // Keep track of the selected day
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const getNextDate = (selectedDay) => {
    const daysOfWeek = {
      Saturday: 6,
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
    };
    const today = new Date();
    const todayDay = today.getDay(); // Current day as a number (0-6)
    const targetDay = daysOfWeek[selectedDay]; // Target day as a number (0-6)

    // Calculate the number of days until the next occurrence of the selected day
    const daysUntilTarget = (targetDay - todayDay + 7) % 7 || 7; // Default to 7 if the same day
    const nextDate = new Date(today);
    nextDate.setDate(today.getDate() + daysUntilTarget); // Set to next occurrence

    return nextDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  const handleSubmit = async () => {
    if (!name || !mobile || !day) {
      setError('يرجى ملء جميع الحقول');
      return;
    }
    ReactPixel.trackCustom( 'doctorـreservation_submit'); // Facebook Pixel tracking for WhatsApp click
    setLoading(true);
    setError('');
    setSuccess(false);

    const reservationDate = getNextDate(day); // Calculate next occurrence of the selected day
console.log(reservationDate)
    try {
      const response = await axios.post(
        'https://www.mena4health.com/apis/users/reservations/create',
        {
          name,
          mobile,
          date: reservationDate, // Send the calculated date instead of the day name
          userId: userId,
        }
      );

      console.log('Reservation created successfully:', response.data);

      setSuccess(true);
      setLoading(false);

      setName('');
      setMobile('');
      setDay('');

      setTimeout(() => {
        setShowModal(false);
      }, 2000);
    } catch (err) {
      console.error('Error creating reservation:', err);
      setError('حدث خطأ أثناء إنشاء الحجز');
      setLoading(false);
    }
  };

  if (!showModal) return null;

  return (
    <div
      className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'
      dir='rtl'>
      <div className='bg-[#F6F2EF] dark:bg-[#110130] my-20 rounded-2xl w-[90%] max-w-md relative'>
        <button
          className='absolute top-4 right-4 text-white text-lg'
          onClick={() => setShowModal(false)}>
          ✕
        </button>

        <h3 className='text-2xl font-bold text-white bg-[#4C00FF] py-5 px-6 rounded-t-2xl text-center'>
          حجز موعد
        </h3>

        <div className='space-y-8 p-6'>
          {error && <p className='text-red-500 text-sm'>{error}</p>}
          {success && (
            <p className='text-green-500 text-sm'>
              تم الحجز بنجاح! سيتم إغلاق النافذة قريبًا.
            </p>
          )}

          <div>
            <label className='block text-sm text-[#110130] dark:text-white mb-1'>
              اسم المريض
            </label>
            <input
              type='text'
              value={name}
              onChange={(e) => setName(e.target.value)}
              className='w-full p-3 rounded-lg bg-white text-[#110130] dark:text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#4C00FF]'
              placeholder='أحمد الأحمد'
              disabled={loading || success}
            />
          </div>

          <div>
            <label className='block text-sm text-[#110130] dark:text-white mb-1'>
              رقم الموبايل
            </label>
            <input
              type='text'
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              className='w-full p-3 rounded-lg bg-white text-[#110130] dark:text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#4C00FF]'
              placeholder='أدخل رقم الموبايل..'
              disabled={loading || success}
            />
          </div>

          <div>
            <label className='block text-sm text-[#110130] dark:text-white mb-1'>
              اليوم
            </label>
            <select
              value={day}
              onChange={(e) => setDay(e.target.value)}
              className='w-full p-3 rounded-lg bg-white text-[#110130] dark:text-white focus:outline-none focus:ring-2 focus:ring-[#4C00FF]'
              disabled={loading || success}>
              <option value='' disabled>
                اختر اليوم
              </option>
              <option value='Saturday'>السبت</option>
              <option value='Sunday'>الأحد</option>
              <option value='Monday'>الإثنين</option>
              <option value='Tuesday'>الثلاثاء</option>
              <option value='Wednesday'>الأربعاء</option>
              <option value='Thursday'>الخميس</option>
            </select>
          </div>

          <button
            onClick={handleSubmit}
            className='w-full py-4 bg-[#34C759] text-white rounded-3xl font-bold'
            disabled={loading || success}>
            {loading ? 'تتم المعالجة...' : success ? 'تم الحجز' : 'تأكيد الحجز'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
