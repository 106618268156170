import React, { useRef, useState } from 'react';
import {
  HeartIcon as HeartIconOutline,
  ChatBubbleOvalLeftIcon,
  FaceSmileIcon,
} from '@heroicons/react/24/outline';
import { HeartIcon as HeartIconSolid } from '@heroicons/react/24/solid';
import axios from '../../utilities/axios';
import profile from '../../assets/icons/profile.png';
import Picker from 'emoji-picker-react';
import useRequireAuth from '../../Hooks/useRequireAuth';
import { useNavigate } from 'react-router-dom';

const PostInteraction = ({
  theme,
  userName,
  liked,
  likes = [],
  comments = [],
  ID,
}) => {
  const isAuthenticated = useRequireAuth();
  const [postLiked, setPostLiked] = useState(liked);
  const [likesList, setLikes] = useState(likes);
  const [commentsList, setComments] = useState(comments);
  const [replyContent, setReplyContent] = useState('');
  const [replyingToComment, setReplyingToComment] = useState(null);
  const commentInputRef = useRef(null);
  const [newComment, setNewComment] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showReplyEmojiPicker, setShowReplyEmojiPicker] = useState(false);
  const navigate = useNavigate();

  const handleAction = (action) => {
    if (isAuthenticated) {
      action();
    } else {
      navigate('/new-account');
    }
  };

  const togglePostLike = () => {
    handleAction(() => {
      const updatedLikedState = !postLiked;
      setPostLiked(updatedLikedState);

      axios
        .get(`/likes/likePost?PostId=${ID}`)
        .then((response) => {
          const { data } = response;
          if (data.message === 'Post disliked') {
            setPostLiked(false);
            setLikes((prevLikes) =>
              prevLikes.filter((like) => like.User?.name !== userName)
            );
          } else {
            setPostLiked(true);
            setLikes((prevLikes) => [
              ...prevLikes,
              {
                User: {
                  name: userName,
                  profileImage: data.User?.profileImage || profile,
                },
              },
            ]);
          }
        })
        .catch((error) => {
          console.error('Error liking/unliking the post:', error);
          setPostLiked((prevLiked) => !prevLiked);
        });
    });
  };

  const toggleCommentLike = (id) => {
    handleAction(() => {
      axios
        .get(`/likes/likeComment?CommentId=${id}`)
        .then((response) => {
          const { data } = response;
          console.log({ data });
          if (data.message === 'comment disliked') {
            setComments((prevComments) =>
              prevComments.map((comment) =>
                comment.id === id ? { ...comment, liked: false } : comment
              )
            );
          }
          if (data.CommentId) {
            setComments((prevComments) =>
              prevComments.map((comment) =>
                comment.id === id ? { ...comment, liked: true } : comment
              )
            );
          }
        })
        .catch((error) => {
          console.error('Error liking/unliking the comment:', error);
        });
    });
  };

  const focusCommentInput = () => {
    handleAction(() => {
      if (commentInputRef.current) {
        commentInputRef.current.focus();
      }
    });
  };

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const addComment = () => {
    if (!newComment.trim()) return;

    handleAction(() => {
      axios
        .post('/comments/create', { PostId: ID, content: newComment })
        .then((response) => {
          const { data } = response;
          if (response.status === 200) {
            setComments((prevComments) => [
              ...prevComments,
              {
                id: data.id,
                User: {
                  name: data.User?.name,
                  profileImage: data.User?.profileImage || profile,
                },
                content: data.content,
                liked: false,
                Replies: [],
              },
            ]);
            setNewComment('');
          }
        })
        .catch((error) => {
          console.error('Error adding comment:', error);
        });
    });
  };

  const handleReplyChange = (e) => {
    setReplyContent(e.target.value);
  };

  const replyToComment = (commentId) => {
    if (!replyContent.trim()) return;

    handleAction(() => {
      axios
        .post(`/posts/reply?CommentId=${commentId}`, {
          CommentId: commentId,
          content: replyContent,
        })
        .then((response) => {
          const { data } = response;
          if (response.status === 200) {
            setComments((prevComments) =>
              prevComments.map((comment) =>
                comment.id === commentId
                  ? {
                      ...comment,
                      Replies: [
                        ...(comment.Replies || []),
                        {
                          id: data.id,
                          User: {
                            name: data.User?.name || 'مجهول',
                            profileImage: data.User?.profileImage || profile,
                          },
                          content: data.content,
                          liked: false,
                        },
                      ],
                    }
                  : comment
              )
            );
            setReplyContent('');
            setReplyingToComment(null);
            setShowReplyEmojiPicker(false);
          }
        })
        .catch((error) => {
          console.error('Error replying to comment:', error);
        });
    });
  };

  const onEmojiClick = (emojiObject) => {
    setNewComment((prev) => prev + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const onReplyEmojiClick = (emojiObject) => {
    setReplyContent((prev) => prev + emojiObject.emoji);
    setShowReplyEmojiPicker(false);
  };

  return (
    <div className='bg-[#F6F2EF] dark:bg-[#110130] py-4'>
      <div className='flex flex-col justify-between'>
        <div className='flex items-center justify-between mb-6'>
          <div className='flex items-center gap-2'>
            {postLiked ? (
              <HeartIconSolid
                onClick={togglePostLike}
                className='h-6 w-6 md:h-8 md:w-8 text-[#4C00FF] dark:text-[#4C00FF] cursor-pointer'
              />
            ) : (
              <HeartIconOutline
                onClick={togglePostLike}
                className='h-6 w-6 md:h-8 md:w-8 text-[#4C00FF] dark:text-[#4C00FF] cursor-pointer'
              />
            )}
            <ChatBubbleOvalLeftIcon
              onClick={focusCommentInput}
              className='h-6 w-6 md:h-8 md:w-8 text-[#110130] dark:text-white cursor-pointer'
            />
          </div>
        </div>

        <div className='flex items-center mb-6'>
          {likesList.length > 0 && (
            <>
              <div className='flex -space-x-4'>
                {likesList.slice(0, 3).map((like, index) => (
                  <img
                    key={index}
                    src={like.User?.profileImage || profile}
                    alt={like.User?.name || 'مجهول'}
                    className={`${
                      likesList.length > 1 ? '-ml-3' : ''
                    } w-8 h-8 md:w-10 md:h-10 rounded-full border-2 border-white dark:border-[#110130]`}
                  />
                ))}
              </div>
              <span className='text-[#110130] dark:text-white mr-5 text-sm md:text-base'>
                تم الاعجاب بواسطة{' '}
                <strong>{likesList[0]?.User?.name || 'مجهول'}</strong> و{' '}
                {likesList.length} آخرين
              </span>
            </>
          )}
        </div>

        <div className='flex flex-col mt-4 space-y-4'>
          {commentsList.map((comment) => (
            <div key={comment.id} className='flex flex-col space-y-2'>
              <div className='flex items-center justify-between'>
                <div className='flex items-center gap-2'>
                  <img
                    src={comment.User?.profileImage || profile}
                    alt='Comment User'
                    className='w-8 h-8 md:w-10 md:h-10 rounded-full'
                  />
                  <div className='flex flex-col'>
                    <span className='font-bold text-[#110130] dark:text-white text-sm md:text-xl'>
                      {comment.User?.name || 'مجهول'}
                    </span>
                    <span className='text-sm md:text-lg text-[#616161]'>
                      {comment.content}
                    </span>
                  </div>
                </div>
                <div className='flex items-center gap-4'>
                  <span
                    className='text-[#4C00FF] dark:text-[#4C00FF] cursor-pointer text-sm md:text-xl'
                    onClick={() => setReplyingToComment(comment.id)}>
                    رد
                  </span>
                  {comment.liked ? (
                    <HeartIconSolid
                      onClick={() => toggleCommentLike(comment.id)}
                      className='h-6 w-6 md:h-8 md:w-8 text-[#4C00FF] dark:text-[#4C00FF] cursor-pointer'
                    />
                  ) : (
                    <HeartIconOutline
                      onClick={() => toggleCommentLike(comment.id)}
                      className='h-6 w-6 md:h-8 md:w-8 text-[#4C00FF] dark:text-[#4C00FF] cursor-pointer'
                    />
                  )}
                </div>
              </div>
              {comment.Replies && comment.Replies.length > 0 && (
                <div className='pr-8 mt-2'>
                  {comment.Replies.map((reply) => (
                    <div
                      key={reply.id}
                      className='flex items-center gap-2 mb-2'>
                      <img
                        src={reply.User?.profileImage || profile}
                        alt='Reply User'
                        className='w-6 h-6 md:w-8 md:h-8 rounded-full'
                      />
                      <div className='flex flex-col'>
                        <span className='font-bold text-[#110130] dark:text-white text-xs md:text-xl'>
                          {reply.User?.name || 'مجهول'}
                        </span>
                        <span className='text-xs md:text-lg text-[#616161]'>
                          {reply.content}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {replyingToComment === comment.id && (
                <div className='flex items-center mt-2 px-7 relative'>
                  <FaceSmileIcon
                    className='h-6 w-6 md:h-8 md:w-8 text-[#4C00FF] dark:text-[#4C00FF] cursor-pointer ml-2'
                    onClick={() =>
                      setShowReplyEmojiPicker(!showReplyEmojiPicker)
                    }
                  />
                  {showReplyEmojiPicker && (
                    <div className='absolute z-10'>
                      <Picker onEmojiClick={onReplyEmojiClick} />
                    </div>
                  )}
                  <input
                    type='text'
                    placeholder='اكتب ردك...'
                    className='flex-grow bg-transparent border-none focus:outline-none text-[#110130] dark:text-white'
                    value={replyContent}
                    onChange={handleReplyChange}
                  />
                  <button
                    className='text-[#4C00FF] dark:text-[#4C00FF] ml-4 text-sm md:text-base'
                    onClick={() => replyToComment(comment.id)}>
                    نشر
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
        <div
          className={`${
            theme === 'dark' ? 'custom-border-dark' : 'custom-border-light'
          } mt-7 flex items-center py-4 relative`}>
          <FaceSmileIcon
            className='h-6 w-6 md:h-8 md:w-8 text-[#4C00FF] dark:text-[#4C00FF] cursor-pointer ml-2'
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          />
          {showEmojiPicker && (
            <div className='absolute z-10'>
              <Picker onEmojiClick={onEmojiClick} />
            </div>
          )}
          <input
            type='text'
            placeholder='قم بإضافة تعليقك...'
            className='flex-grow bg-transparent border-none focus:outline-none text-[#110130] dark:text-white text-sm md:text-base'
            ref={commentInputRef}
            dir='rtl'
            value={newComment}
            onChange={handleCommentChange}
          />
          <button
            className='text-[#4C00FF] dark:text-[#4C00FF] ml-4 text-sm md:text-base'
            onClick={addComment}>
            نشر
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostInteraction;
