import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from '../pages/Login/Login';
import ForgetPassword from '../pages/Login/ForgetPassword';
import Verification from '../pages/Login/Verification';
import NewAccount from '../pages/Login/NewAccount';
import ChatList from '../pages/Chats/ChatList';
import Chat from '../pages/Chats/Chat';
import Laboratory from '../pages/Users/Laboratory';
import Hadil from '../pages/HadilLab/HadilLab';
import BaseaLab from '../pages/BasraLab/BasraLab';
import PostChat from '../components/Post/Chat';
import LaboChat from '../pages/LaboChat/LaboChat';
import ProtectedRoute from '../utilities/ProtectedRoute';
import ChatFromNotification from '../pages/ChatFromNotification/chatFromNotification';
import KarbalaLab from '../pages/karbalaLab/karbalaLab';
import Doctor from '../pages/Doctors/Doctor';
const RoutesComponent = () => {
  return (
    <Routes>
      {/* Redirect base domain to /user/1 */}
      <Route path='/' element={<Navigate to='/hadilLab' replace />} />

      {/* Public Routes */}
      <Route path='/hadilLab' element={<Hadil />} />
      <Route path='/basraLab' element={<BaseaLab />} />
      <Route path='/karbalaLab' element={<KarbalaLab />} />
      <Route path='/login' element={<Login />} />
      <Route path='/forget-password' element={<ForgetPassword />} />
      <Route path='/verification' element={<Verification />} />
      <Route path='/new-account' element={<NewAccount />} />
      <Route path='/directMessage' element={<LaboChat />} />
      <Route path='/doctor/:id' element={<Doctor />} />

      {/* Temporary Routes */}
      <Route path='/user/:id' element={<Laboratory />} />
      <Route path='/post-chat' element={<PostChat />} />

      {/* Protected Routes */}
      <Route
        path='/chat-list'
        element={
          <ProtectedRoute>
            <ChatList />
          </ProtectedRoute>
        }
      />
      <Route
        path='/chat'
        element={
          <ProtectedRoute>
            <Chat />
          </ProtectedRoute>
        }
      />

      <Route
        path='/chat/:conversationId'
        element={
          <ProtectedRoute>
            <ChatFromNotification />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default RoutesComponent;
