import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://www.mena4health.com/apis',
});

instance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      localStorage.removeItem('accessToken');

      // window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default instance;
