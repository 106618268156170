import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import flag from '../../assets/flags/iraq.png';
import logo_dark from '../../assets/logos/logo-dark.svg';
import logo_light from '../../assets/logos/logo-light.svg';
import axios from '../../utilities/axios';
import { toastMsg } from '../../App';
import {
  auth,
  setupRecaptcha,
  signInWithPhoneNumber,
} from '../../utilities/firebase';

const Register = () => {
  const lat =0;
  const lon =0;
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  useEffect(() => {
    i18n.changeLanguage('ar');
    document.documentElement.setAttribute('dir', 'rtl');
  }, [i18n]);

  const toggleTheme = () => {
    console.log({ theme });
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  const handleRegister = async () => {
    setNameErrorMessage('');
    setPhoneErrorMessage('');

    if (!name) {
      setNameErrorMessage('يرجى إدخال الاسم');
    }
    if (!phoneNumber) {
      setPhoneErrorMessage('يرجى إدخال رقم الهاتف');
    }
    if (phoneNumber.length < 9) {
      setPhoneErrorMessage('رقم الهاتف يجب أن يكون 9 رقمًا على الأقل');
      return;
    }

    if (!name || !phoneNumber || phoneNumber.length < 9) {
      return;
    }

    try {
      const response = await axios.post('/users/register', {
        name,
        mobile: phoneNumber,
        role: 'patient',
        lat,
        lon,
      });
      console.log({ response });
      if (response.status === 200) {
        toastMsg.success('تم التسجيل بنجاح. تم إرسال الرمز إلى هاتفك.');

        setupRecaptcha();

        const phoneNumberWithCountryCode = `+964${phoneNumber}`;
        const appVerifier = window.recaptchaVerifier;

        try {
          const confirmationResult = await signInWithPhoneNumber(
            auth,
            phoneNumberWithCountryCode,
            appVerifier
          );
          window.confirmationResult = confirmationResult;
          navigate('/verification', {
            state: { phoneNumber: phoneNumber },
          });
        } catch (error) {
          console.error('Error sending OTP:', error);
          toastMsg.error('حدث خطأ أثناء إرسال الرمز. حاول مرة أخرى.');
        }
      } else {
        toastMsg.error(response.data.message || 'فشل التسجيل');
      }
    } catch (error) {
      console.error('Error registering:', error);
      toastMsg.error(
        error.response?.data?.error || 'حدث خطأ أثناء التسجيل'
      );
    }
  }




  const handleNameChange = (e) => {
    setName(e.target.value);
    if (nameErrorMessage) {
      setNameErrorMessage('');
    }
  };

  const handlePhoneNumberChange = (e) => {
    let value = e.target.value;
    if (value.startsWith('0')) {
      value = value.slice(1);
    }
    setPhoneNumber(value);
    if (phoneErrorMessage && value.length >= 9) {
      setPhoneErrorMessage('');
    }
  };

  const handleverify = () => {
    navigate('/login');
  };

  return (
    <div className='flex flex-col !overflow-hidden items-center min-h-screen max-h-screen bg-[#F6F2EF] dark:bg-[#110130] font-arabic'>
      <div
        onClick={toggleTheme}
        className='flex justify-center mt-4 p-4 mb-[5rem]'>
        <img
          src={theme === 'light' ? logo_light : logo_dark}
          alt='Mena Logo'
          className='h-[32px] w-[150px]'
        />
      </div>
      <div className='w-full px-5 h-[435px] flex items-center justify-center mb-[7rem]'>
        <div className='h-full p-6 space-y-6 bg-white dark:bg-login-dark-bg rounded-3xl shadow-md'>
          <h1 className='text-2xl font-arabic mt-12 font-bold text-center text-gray-900 dark:text-white'>
            {t('welcome')}
          </h1>
          <p className='font-arabic text-[15px] text-center font-bold text-gray-600 dark:text-gray-300'>
            {t('login_message')}
          </p>
          <div className='space-y-4'>
            <div className='flex flex-col'>
              <input
                type='text'
                id='name'
                value={name}
                onChange={handleNameChange}
                className={`block w-full px-4 py-2 placeholder:text-right text-gray-700 border-[1px] rounded-full focus:outline-none bg-transparent dark:text-gray-200 ${nameErrorMessage ? 'border-red-500' : 'border-[#AB93FF]'
                  }`}
                placeholder={t('name')}
              />
              {nameErrorMessage && (
                <div className='text-red-500 text-right'>
                  {nameErrorMessage}
                </div>
              )}
            </div>
            <div className='flex flex-col'>
              <div className='flex items-center gap-3'>
                <input
                  type='tel'
                  id='phone'
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  className={`block w-full px-4 py-2 placeholder:text-right text-gray-700 border-[1px] rounded-full focus:outline-none bg-transparent dark:text-gray-200 ${phoneErrorMessage ? 'border-red-500' : 'border-[#AB93FF]'
                    }`}
                  placeholder={t('phone_number')}
                />
                <div className='flex justify-center items-center px-[10px] py-[8px] bg-transparent border-[1px] border-[#AB93FF] rounded-full w-[110px]'>
                  <span className='text-gray-700 dark:text-gray-200 ml-2'>
                    964+
                  </span>
                  <img
                    src={flag}
                    alt='Iraq Flag'
                    className='h-4 w-4 rounded-full'
                  />
                </div>
              </div>
              {phoneErrorMessage && (
                <div className='text-red-500 text-right'>
                  {phoneErrorMessage}
                </div>
              )}
            </div>
            <div className='w-full flex justify-center !mt-8'>
              <button
                onClick={handleRegister}
                className='w-[95%] h-[44px] text-white bg-[#4C00FF] rounded-full hover:bg-blue-700 focus:ring focus:ring-blue-500'>
                {t('new_account')}
              </button>
            </div>
          </div>
          <div className='text-center mt-8'>
            <p>
              <a
                onClick={handleverify}
                className='hover:underline dark:text-[#C8F4FD] text-[#4C00FF]'>
                {t('prev_account')}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div id='recaptcha-container'></div>
      <div className='flex justify-center mb-8'>
        <p className='text-[#767575] dark:text-[#C3C9FF]'>
          سياسة الاستخدام والخصوصية
        </p>
      </div>
    </div>
  );
};

export default Register;
