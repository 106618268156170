import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import flag from '../../assets/flags/iraq.png';
import logo_dark from '../../assets/logos/logo-dark.svg';
import logo_light from '../../assets/logos/logo-light.svg';

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  useEffect(() => {
    const currentLanguage = i18n.language;
    i18n.changeLanguage('ar');
    document.documentElement.setAttribute('dir', 'rtl');
  }, [i18n.language]);
  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };
  const handleLogin = () => {
    const token = 'fake-token';
    sessionStorage.setItem('mena-token', token);
     navigate('/new-account');;
  };

  return (
    <div className='flex flex-col md:!overflow-hidden items-center min-h-screen  max-h-screen bg-[#F6F2EF] dark:bg-[#110130] font-arabic'>
      <div
        onClick={toggleTheme}
        className='flex justify-center mt-10 mb-[7rem]'>
        <img
          src={theme === 'light' ? logo_light : logo_dark}
          alt='Mena Logo'
          className='h-[32px] w-[150px]'
        />
      </div>
      <div className='w-full px-5 h-[375px] flex items-center justify-center mb-[7rem]'>
        <div className='h-full p-6 space-y-6 bg-white dark:bg-login-dark-bg rounded-3xl shadow-md'>
          <h1 className='text-2xl font-arabic mt-12 font-bold text-center text-gray-900 dark:text-white'>
            {t('welcome')}
          </h1>
          <p className=' font-arabic text-[15px] text-right font-bold text-gray-600 dark:text-gray-300'>
            {t('login_message_2')}
          </p>
          <div className='space-y-4'>
            <div className='flex items-center gap-3'>
              <input
                type='text'
                id='phone'
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className='block w-full px-4 py-2 text-gray-700 border-[1px] border-[#AB93FF] rounded-lg focus:outline-none bg-transparent dark:text-gray-200'
                placeholder={t('your_problem')}
              />
            </div>
            <div className='w-full flex justify-center !mt-12'>
              <button
                onClick={handleLogin}
                className='w-[95%] h-[44px] text-white bg-[#4C00FF] rounded-full hover:bg-blue-700 focus:ring focus:ring-blue-500'>
                {t('send')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex justify-right mb-8'>
        <p className='text-[#767575] dark:text-[#C3C9FF] mr-6'>{t('skip')}</p>
      </div>
    </div>
  );
};

export default Login;
