import { useNavigate, useLocation } from 'react-router-dom';
import logo_light from '../../assets/logos/logo-chat-light.svg';
import logo_dark from '../../assets/logos/logo-chat-dark.svg';
import { Bars3Icon, XMarkIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';
import refreshIcon from '../../assets/icons/refresh.svg';
import refreshwhite from '../../assets/icons/refresh-white.svg';
import { useState, useEffect, useRef } from 'react';
import powerd from '../../assets/logos/powerd.svg';
import PrivacyPolicyModal from '../PrivacyPolicy/PrivacyPolicy';

const MainHeader = ({ showRefresh, onRefresh, showBack = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [theme, setTheme] = useState('light');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [linkName, setLinkName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [activeMenu, setActiveMenu] = useState('');
  const menuRef = useRef(null);

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath === '/') {
      setActiveMenu('الرئيسية');
    } else if (currentPath === '/chat') {
      setActiveMenu('أسال الذكاء الاصطناعي');
    } else {
      setActiveMenu('الرئيسية');
    }
  }, [location.pathname]);

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
    setIsMenuOpen(false);
    navigate(menu === 'الرئيسية' ? '/' : '/chat');
  };
  const openModal = (name) => {
    setIsModalOpen(true);
    setLinkName(name);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      className='relative py-5 px-4 flex justify-around bg-[#f6f2ef] dark:bg-[#110130] items-center'
      dir='rtl'>
      <div className='md:hidden flex items-center'>
        {isMenuOpen ? (
          <XMarkIcon
            className='h-6 w-6 text-black dark:text-white cursor-pointer'
            onClick={toggleMenu}
          />
        ) : (
          <Bars3Icon
            className='h-[2.5rem] w-[2.5rem] text-black dark:text-white cursor-pointer'
            onClick={toggleMenu}
          />
        )}
      </div>
      <div
        ref={menuRef}
        className={`fixed md:bg-transparent md:static  top-0 right-0 md:right-auto md:top-auto transform ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        } md:transform-none transition-transform duration-300 ease-in-out z-50 p-7 md:p-0 bg-white dark:bg-[#110130] shadow-lg md:shadow-none w-1/2 md:w-auto h-screen md:h-auto flex flex-col md:flex-row items-start md:items-center justify-start md:justify-end gap-6 md:gap-10`}>
        <XMarkIcon
          className='h-6 w-6 md:hidden text-black dark:text-white cursor-pointer'
          onClick={toggleMenu}
        />

        <a
          onClick={() => handleMenuClick('الرئيسية')}
          className={`cursor-pointer hover:text-[#4C00FF]  text-[24px] leading-[36px] text-center ${
            activeMenu === 'الرئيسية'
              ? 'text-[#4C00FF]  font-bold'
              : 'text-black dark:text-white'
          }`}
          style={{ fontFamily: 'IBM Plex Arabic, sans-serif' }}>
          الرئيسية
        </a>
        <a
          onClick={() => handleMenuClick('أسال الذكاء الاصطناعي')}
          className={`cursor-pointer text-[18px] hover:text-[#4C00FF]  md:text-[24px] leading-[36px] text-center ${
            activeMenu === 'أسال الذكاء الاصطناعي'
              ? 'text-[#4C00FF]  font-bold'
              : 'text-black dark:text-white'
          }`}
          style={{ fontFamily: 'IBM Plex Arabic, sans-serif' }}>
          أسال الذكاء الاصطناعي
        </a>
        <a
          onClick={() => openModal('الشروط والأحكام')}
          className={`cursor-pointer text-[18px]  md:text-[24px] leading-[36px] hover:text-[#4C00FF] text-center text-black dark:text-white `}
          style={{ fontFamily: 'IBM Plex Arabic, sans-serif' }}>
          الشروط والأحكام
        </a>
        <a
          onClick={() => openModal('سياسة الخصوصية')}
          className={`cursor-pointer text-[18px]  md:text-[24px] leading-[36px] text-center hover:text-[#4C00FF] text-black dark:text-white `}
          style={{ fontFamily: 'IBM Plex Arabic, sans-serif' }}>
          سياسة الخصوصية
        </a>
      </div>
      <div className='flex-1 md:flex-none text-center z-30'>
        {showBack && (
          <ArrowLeftIcon
            className='absolute left-4 h-6 w-6 text-black dark:text-white cursor-pointer'
            onClick={() => navigate(-1)}
          />
        )}
        <div className='relative ml-14 md:ml-0'>
          <img
            src={theme === 'light' ? logo_light : logo_dark}
            onClick={toggleTheme}
            alt='Mena Logo'
            className='inline-block h-[52px] w-[100px] md:w-[120px] cursor-pointer  md:ml-10'
          />
          <p className=' text-[#4c00ff] text-sm  dark:text-white  absolute left-2 top-4 md:-left-9 md:top-4'>
            powered by{' '}
          </p>
        </div>
      </div>
      <PrivacyPolicyModal
        linkName={linkName}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </div>
  );
};

export default MainHeader;
