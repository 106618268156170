import React, { useRef, useEffect } from 'react';
import { ShareIcon, SunIcon, MoonIcon } from '@heroicons/react/24/outline';

const DropdownMenu = ({ onToggleTheme, theme, closeDropdown }) => {
  const dropdownRef = useRef(null);

  const shareLink = () => {
    if (navigator.share) {
      navigator.share({
        title: 'شارك هذا الملف الشخصي',
        text: 'تحقق من هذا الملف الشخصي',
        url: window.location.href,
      });
    } else {
      alert('ميزة المشاركة غير مدعومة في هذا المتصفح');
    }
    closeDropdown();
  };

  const handleMouseLeave = () => {
    closeDropdown();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      ref={dropdownRef}
      className='absolute left-0 mt-2 w-48 bg-white dark:bg-[#110130] shadow-lg rounded-md py-2 z-50'
      onMouseLeave={handleMouseLeave}>
      <button
        className='flex items-center w-full px-4 py-2 gap-1 text-sm text-left text-[#110130] dark:text-white hover:bg-gray-200 dark:hover:bg-gray-800'
        onClick={shareLink}>
        <ShareIcon className='h-5 w-5 mr-3' />
        مشاركة الرابط
      </button>
      <button
        className='flex items-center w-full px-4 py-2 gap-1 text-sm text-left text-[#110130] dark:text-white hover:bg-gray-200 dark:hover:bg-gray-800'
        onClick={() => {
          onToggleTheme();
          closeDropdown();
        }}>
        {theme === 'light' ? (
          <>
            <MoonIcon className='h-5 w-5 mr-3' />
            وضع ليلي
          </>
        ) : (
          <>
            <SunIcon className='h-5 w-5 mr-3' />
            وضع نهاري
          </>
        )}
      </button>
    </div>
  );
};

export default DropdownMenu;
