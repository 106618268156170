import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCzn3_1wzt0pq43UqzIOOYwu96cXo2XCP0',
  authDomain: 'mena-789a9.firebaseapp.com',
  projectId: 'mena-789a9',
  storageBucket: 'mena-789a9.appspot.com',
  messagingSenderId: '137367944497',
  appId: '1:137367944497:web:5fc237ac9cdd46ff238410',
  measurementId: 'G-5S07QJ7KP2',
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

const setupRecaptcha = () => {
  window.recaptchaVerifier = new RecaptchaVerifier(
    auth,
    'recaptcha-container',
    {
      size: 'invisible',
      callback: (response) => {
        console.log('reCAPTCHA solved', response);
      },
    }
  );
};
export {
  messaging,
  getToken,
  onMessage,
  auth,
  setupRecaptcha,
  signInWithPhoneNumber,
  analytics
};
