import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import flag from '../../assets/flags/iraq.png';
import logo_dark from '../../assets/logos/logo-dark.svg';
import logo_light from '../../assets/logos/logo-light.svg';
import { toastMsg } from '../../App';
import axios from '../../utilities/axios';

import {
  auth,
  setupRecaptcha,
  signInWithPhoneNumber,
} from '../../utilities/firebase';

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  useEffect(() => {
    i18n.changeLanguage('ar');
    document.documentElement.setAttribute('dir', 'rtl');
  }, [i18n.language]);

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  const handlePhoneNumberChange = (e) => {
    let value = e.target.value;

    value = value.replace(
      /[٠١٢٣٤٥٦٧٨٩]/g,
      (digit) => '0123456789'['٠١٢٣٤٥٦٧٨٩'.indexOf(digit)]
    );

    if (value.startsWith('0')) {
      value = value.slice(1);
    }

    setPhoneNumber(value);

    if (value.length < 9) {
      setErrorMessage('رقم الهاتف يجب أن يكون 11 رقمًا على الأقل');
    } else {
      setErrorMessage('');
    }
  };

  const handleLogin = async () => {
    if (phoneNumber.length < 9) {
      setErrorMessage('رقم الهاتف يجب أن يكون 11 رقمًا على الأقل');
      return;
    }

    setLoading(true);
    setupRecaptcha();

    const phoneNumberWithCountryCode = `+964${phoneNumber}`;

    try {
      const response = await axios.get(`/users/login?mobile=${phoneNumber}`);

      if (response.data === 'code sent successfully') {
        const appVerifier = window.recaptchaVerifier;

        try {
          const confirmationResult = await signInWithPhoneNumber(
            auth,
            phoneNumberWithCountryCode,
            appVerifier
          );
          window.confirmationResult = confirmationResult;
          toastMsg.success('تم إرسال الرمز بنجاح.');
          navigate('/verification', { state: { phoneNumber } });
        } catch (firebaseError) {
          console.error('Error during Firebase phone sign-in:', firebaseError);
          toastMsg.error('حدث خطأ أثناء إرسال الرمز. يرجى المحاولة مرة أخرى.');
        }
      } else {
        throw new Error('User needs to sign up');
      }
    } catch (error) {
      console.error('Error during login:', error);
      toastMsg.error('أنت مستخدم جديد، يجب عليك التسجيل.');
      navigate('/new-account');
    } finally {
      setLoading(false);
    }
  };

  const handleNewUser = () => {
    navigate('/new-account');
  };

  return (
    <div className='flex flex-col !overflow-hidden items-center min-h-screen max-h-screen bg-[#F6F2EF] dark:bg-[#110130] font-arabic'>
      <div
        onClick={toggleTheme}
        className='flex justify-center mt-4 p-4 mb-[5rem]'>
        <img
          src={theme === 'light' ? logo_light : logo_dark}
          alt='Mena Logo'
          className='h-[32px] w-[150px]'
        />
      </div>
      <div className='w-full px-5 h-[375px] flex items-center justify-center mb-[7rem]'>
        <div className='h-full p-6 space-y-6 bg-white dark:bg-login-dark-bg rounded-3xl shadow-md'>
          <h1 className='text-2xl font-arabic mt-12 font-bold text-center text-gray-900 dark:text-white'>
            {t('welcome')}
          </h1>
          <p className='font-arabic text-[15px] text-center font-bold text-gray-600 dark:text-gray-300'>
            {t('login_message')}
          </p>
          <div className='space-y-4'>
            <div className='flex items-center gap-3'>
              <input
                type='tel'
                id='phone'
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                className={`block w-full placeholder:text-right px-4 py-2 text-gray-700 border-[1px] rounded-full focus:outline-none bg-transparent dark:text-gray-200 ${
                  errorMessage ? 'border-red-500' : 'border-[#AB93FF]'
                }`}
                placeholder={t('phone_number')}
              />
              <div className='flex justify-center items-center px-[10px] py-[8px] bg-transparent border-[1px] border-[#AB93FF] rounded-full w-[110px]'>
                <span className='text-gray-700 dark:text-gray-200 ml-2'>
                  964+
                </span>
                <img
                  src={flag}
                  alt='Iraq Flag'
                  className='h-4 w-4 rounded-full'
                />
              </div>
            </div>
            {errorMessage && (
              <div className='text-red-500 text-right'>{errorMessage}</div>
            )}
            <div className='w-full flex justify-center !mt-8'>
              <button
                onClick={handleLogin}
                disabled={loading}
                className='w-[95%] h-[44px] text-white bg-[#4C00FF] rounded-full hover:bg-blue-700 focus:ring focus:ring-blue-500 flex justify-center items-center'>
                {loading ? <div className='loader'></div> : t('login')}
              </button>
            </div>
          </div>
          <div className='text-center mt-8'>
            <p>
              <a
                disabled={loading}
                onClick={handleNewUser}
                className='hover:underline cursor-pointer dark:text-[#C8F4FD] text-[#4C00FF]'>
                {t('new_user')}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div id='recaptcha-container'></div>
      <div className='flex justify-center mb-8'>
        <p className='text-[#767575] dark:text-[#C3C9FF]'>
          سياسة الاستخدام والخصوصية
        </p>
      </div>
    </div>
  );
};

export default Login;
