import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../../utilities/axios';
import { toastMsg } from '../../App';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import logo_dark from '../../assets/logos/logo-dark.svg';
import logo_light from '../../assets/logos/logo-light.svg';
import refresh from '../../assets/icons/refresh.png';
import { useAuth } from '../../Hooks/authContext';

const Verification = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [theme, setTheme] = useState('light');
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const phoneNumber = location.state?.phoneNumber;
  const fcmToken = localStorage.getItem('fcmToken');

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  useEffect(() => {
    i18n.changeLanguage('ar');
    document.documentElement.setAttribute('dir', 'rtl');
  }, [i18n.language]);

  const handleVerify = async () => {
    if (!verificationCode) {
      setErrorMessage('يرجى إدخال رمز التحقق');
      return;
    }
    setLoading(true);

    try {
      const result = await window.confirmationResult.confirm(verificationCode);
      const user = result.user;

      const idToken = (await user.getIdToken()) ?? '';
      console.log('ID Token:', idToken);

      const response = await axios.post('/users/verify', {
        mobile: phoneNumber,
        idToken: idToken,
        fcmToken: fcmToken,
      });

      if (response.status === 200) {
        const accessToken = response.data;
        login(accessToken);
        localStorage.setItem('accessToken', accessToken);
        const profileResponse = await axios.get('/users/profile');

        if (profileResponse.status === 200) {
          const userProfile = profileResponse.data;
          localStorage.setItem('senderID', userProfile.id);
          localStorage.setItem('user_profile', JSON.stringify(userProfile));
        }
        toastMsg.success('تم التحقق بنجاح.');
        navigate('/user/39');
      } else {
        setLoading(false);

        toastMsg.error(response.data.message || 'فشل التحقق');
      }
    } catch (error) {
      setLoading(false);

      console.error('Error during OTP verification:', error);
      toastMsg.error(error.response?.data?.message || 'حدث خطأ أثناء التحقق');
    }
  };

  const handleVerificationCodeChange = (e) => {
    let value = e.target.value;

    value = value.replace(
      /[٠١٢٣٤٥٦٧٨٩]/g,
      (digit) => '0123456789'['٠١٢٣٤٥٦٧٨٩'.indexOf(digit)]
    );

    setVerificationCode(value);

    if (errorMessage) {
      setErrorMessage('');
    }
  };

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  return (
    <div className='flex flex-col md:!overflow-hidden items-center min-h-screen max-h-screen bg-[#F6F2EF] dark:bg-[#110130] font-arabic'>
      <div className='w-full relative mt-4 mb-[5rem] p-4 flex justify-center items-center'>
        <ArrowLeftIcon
          className='absolute left-4 h-6 w-6 text-black dark:text-white cursor-pointer'
          onClick={() => navigate(-1)}
        />
        <img
          src={theme === 'light' ? logo_light : logo_dark}
          alt='Mena Logo'
          onClick={toggleTheme}
          className='h-[32px] w-[150px]'
        />
      </div>
      <div className='w-full px-5 h-[400px] flex items-center justify-center mb-[7rem]'>
        <div className='h-full p-6 space-y-6 bg-white dark:bg-login-dark-bg rounded-3xl shadow-md'>
          <h1 className='text-2xl font-arabic mt-12 font-bold text-center text-gray-900 dark:text-white'>
            {t('welcome')}
          </h1>
          <p className='font-arabic text-[15px] text-right font-bold text-gray-600 dark:text-gray-300'>
            {t('login_message_2')}
          </p>
          <div className='space-y-4'>
            <div className='flex flex-col'>
              <input
                type='tel'
                id='verificationCode'
                value={verificationCode}
                onChange={handleVerificationCodeChange}
                className={`block w-full text-center px-4 py-2 placeholder:text-center text-gray-700 border-[1px] rounded-[21px] focus:outline-none bg-transparent dark:text-gray-200 ${
                  errorMessage ? 'border-red-500' : 'border-[#AB93FF]'
                }`}
                placeholder={t('verification_code')}
              />
              {errorMessage && (
                <div className='text-red-500 text-center'>{errorMessage}</div>
              )}
            </div>
            <div className='w-full flex justify-center !mt-9'>
              <button
                onClick={handleVerify}
                disabled={loading}
                className='w-[95%] h-[44px] text-white bg-[#4C00FF] rounded-full hover:bg-blue-700 focus:ring focus:ring-blue-500'>
                {loading ? <div className='loader'></div> : t('verify')}
              </button>
            </div>
          </div>
          <div className='text-center mb-9 mt-8'>
            <p>
              <a
                href='/verification'
                className='flex items-center justify-center mb-9 hover:underline dark:text-[#3F4344] text-[#4C00FF] '>
                <img className='px-1 w-[20px]' src={refresh} />
                {t('resend')}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className='flex justify-center mb-8'>
        <p className='text-[#767575] dark:text-[#C3C9FF]'>
          سياسة الاستخدام والخصوصية
        </p>
      </div>
    </div>
  );
};

export default Verification;
