import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      appName: 'Mena',
      welcome: 'Welcome back',
      login_message:
        'Please enter your phone number to login, and we will send you a verification code.',
      phone_number: 'Phone Number',
      login: 'Login',
      new_user: 'New User?',
      sign_up: 'Sign Up',
      language: {
        english: 'English',
        arabic: 'Arabic',
      },
      forget_password: 'Enter the identification code',
      identification_code: 'Enter the identification code',
      verify: 'Verify',
      back_login: 'Back to Login',
    },
  },
  ar: {
    translation: {
      appName: 'مينا',
      name: 'الأسم',
      new_account: 'انشاء حساب',
      prev_account: 'لديك حساب مسبقا',
      welcome: 'اهلا بعودتك🎉',
      login_message:
        'يرجى استخدام رقم الهاتف لستجيل الدخول. وسوف نرسل لك رمز التحقق على رقمك للتأكيد',
      phone_number: 'رقم الهاتف',
      login_message_2:
        'لديك مشكلة صحية وتريد أن تستشير الذكاء الصنعي عن هذه المشكلة سوف يقترح لك الحلول',
      login: 'تسجيل دخول',
      your_problem: 'ماهي مشكلتك الصحية، اكتب هنا ...',
      new_user: 'مستخدم جديد؟',
      sign_up: 'اشتراك',
      send: 'ارسال',
      skip: 'تخطي',
      resend: 'اعادة ارسال الكود',
      verification_code: 'ادخل رمز التحقق',
      language: {
        english: 'الإنجليزية',
        arabic: 'العربية',
      },
      forget_password: 'ادخل رمز التعريف',
      identification_code: 'ادخل رمز التعريف هنا',
      verify: 'تأكيد',
      back_login: 'الرجوع للخلف',
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
